import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  HashtagInfo,
  UserInfo,
  HashtagBadge,
  ITransaction,
  ImageType,
  ImageEntityTypes,
  MapDoc,
  NetworkInfo
} from "../shared/entities";
import { Observable, of } from "rxjs";
import { map, catchError, filter } from "rxjs/operators";
import { UploadService } from "./upload.service";
import { SearchService, KMSSearchResults, IndexName } from "./search.service";
import { UserService } from "./user.service";
import { Utilities } from "src/app/shared/utils";
import { Booking } from "../shared/webinars-entities";
import { ConfigurationService } from "./configuration.service";
import { Network } from "vis";

@Injectable({
  providedIn: "root"
})
export class HashtagService {
  private defaultAvatar$: Observable<string>;
  private defaultThumbnailAvatar$: Observable<string>;

  constructor(
    private httpClient: HttpClient,
    private readonly uploaderSvc: UploadService,
    private searchSvc: SearchService,
    private userSvc: UserService,
    private readonly configSvc: ConfigurationService
  ) {
    this.configSvc.assetsConfig.pipe(filter(r => r != null)).subscribe(() => {
      this.defaultAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Hashtag,
        ImageType.Avatar,
        `portal/api/hashtag/avatar/default/get`
      );

      this.defaultThumbnailAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Hashtag,
        ImageType.Thumbnail,
        `portal/api/hashtag/avatar/default/thumbnail/get`
      );
    });
  }

  //BUR:96 filter by strategic tag
  public searchHashtag(
    query: string,
    params: any = null
  ): Observable<HashtagInfo[]> {
    if (!params) {
      const currentYear = new Date().getFullYear();
      params = {
        // filter: `(isPrivate eq false and isDeprecated eq false and typeId lt 5) or (typeId eq 5 and referenceYear/any(t: t eq ${currentYear}))`,
        filter: `isPrivate eq false and isDeprecated eq false `,
        searchFields: ["name"]
      };
    }
    return this.searchSvc.query("Hashtags", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<HashtagInfo>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToHashInfo(val))
      )
    );
  }

  public searchHashtags(
    hashtags: string[],
    params: any = null
  ): Observable<HashtagInfo[]> {
    if (!params) {
      const currentYear = new Date().getFullYear();
      params = {
        // filter: `(isPrivate eq false and isDeprecated eq false and typeId lt 5) or (typeId eq 5 and referenceYear/any(t: t eq ${currentYear}))`,
        filter: `isPrivate eq false and isDeprecated eq false `,
        searchFields: ["name"]
      };
    }
    let hashtagsToSearch = hashtags.join(',').replace('#','').replace('#','');
    let query = `search.in(name, '${hashtagsToSearch}')`
    return this.searchSvc.query("Hashtags", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<HashtagInfo>) =>
        searchRes.results
          ?.map(result => result.document)
          ?.map(val => MapDoc.docToHashInfo(val))
      )
    );
  }

  public getNetworksByHashtagNames(
    hashtags: string[],
    params: any = null
  ): Observable<NetworkInfo[]> {
    if (!params) {
      const currentYear = new Date().getFullYear();
      params = {
        // filter: `(isPrivate eq false and isDeprecated eq false and typeId lt 5) or (typeId eq 5 and referenceYear/any(t: t eq ${currentYear}))`,
        filter: `isPrivate eq false`,
        searchFields: ["name"]
      };
    }
    let hashtagsToSearch = hashtags.join(',').replace('#','');
    let query = `search.in(hashtag, '${hashtagsToSearch}')`
    return this.searchSvc.query("Networks", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<NetworkInfo>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToNetInfo(val))
      )
    );
  }

  public searchHashtagsInPrivate(
    hashtags: string[],
    params: any = null
  ): Observable<HashtagInfo[]> {
    if (!params) {
      const currentYear = new Date().getFullYear();
      params = {
        // filter: `(isPrivate eq false and isDeprecated eq false and typeId lt 5) or (typeId eq 5 and referenceYear/any(t: t eq ${currentYear}))`,
        filter: `isPrivate eq true and isDeprecated eq false `,
        searchFields: ["name"]
      };
    }
    let hashtagsToSearch = hashtags.join(',').replace('#','');
    let query = `search.in(name, '${hashtagsToSearch}')`
    return this.searchSvc.query("Hashtags", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<HashtagInfo>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToHashInfo(val))
      )
    );
  }


  public searchAllowedHashtag(
    query: string,
    params: any = null
  ): Observable<HashtagInfo[]> {
    if (!params) {
      const currentYear = new Date().getFullYear();
      params = {
        // filter: `(isPrivate eq false and isDeprecated eq false and typeId lt 5) or (typeId eq 5 and referenceYear/any(t: t eq ${currentYear}))`,
        filter: `(typeId eq 1 or typeId eq 5 or typeId eq 6) and isPrivate eq false and isDeprecated eq false `,
        searchFields: ["name"]
      };
    }
    return this.searchSvc.query("Hashtags", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<HashtagInfo>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToHashInfo(val))
      )
    );
  }

  public searchAllowedHashtagFromSearch(
    query: string,
    params: any = null,
    searchMode:string
  ) {
    if (!params) {
      const currentYear = new Date().getFullYear();
      params = {
        // filter: `(isPrivate eq false and isDeprecated eq false and typeId lt 5) or (typeId eq 5 and referenceYear/any(t: t eq ${currentYear}))`,
        filter: `(typeId eq 1 or typeId eq 5 or typeId eq 6) and isPrivate eq false and isDeprecated eq false `,
        searchFields: ["name"],
        queryType:"full"
      };
    }
    // isPrivate eq true and search.ismatch('46f8d683-bc89-4a14-b133-dee50ff0968f','promotingNetworksIds')

    if(searchMode !="starts"){
      return this.searchSvc.searchIn("Hashtags",query.split(" "), params);
    }else{
      return this.searchSvc.queryRaw("Hashtags",query, params);
    }
    // return this.searchSvc.query("Hashtags", query, params);
  }

  public searchPrivateHashtag(
    query: string,
    networkId: string,
    searchMode:string
  ){
    const params = {
      filter: `isPrivate eq true and search.ismatch('${networkId}','promotingNetworksIds')`
    };

    if(searchMode !="starts"){
      return this.searchSvc.externalQuerySearchin("Hashtags",query.split(" "), params);
    }else{
      return this.searchSvc.externalQueryStartsWith("Hashtags",query, params);
    }
    
  }

  public searchHashtagPrivate(
    query: string,
    params: any = null
  ): Observable<HashtagInfo[]> {
    if (!params) {
      params = {
        filter: "isPrivate eq true and isDeprecated eq false",
        searchFields: ["name"]
      };
    }
    return this.searchSvc.query("Hashtags", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<HashtagInfo>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToHashInfo(val))
      )
    );
  }


  public searchRelatedHashtag(query: string): Observable<HashtagInfo[]> {
    query = query?.replace(/^#/, "");
    const params = {
      filter:
        "(typeId eq 1 or typeId eq 5) and isPrivate eq false and isDeprecated eq false and search.ismatch('" +
        Utilities.escapingNames(query) +
        "', '" +
        "name" +
        "', 'full', 'all')",
      includeTotalResultCount: false,
      searchFields: ["name"],
      top: 10
    };
    return this.searchSvc
      .query<HashtagInfo>("hashtags" as any, query, params)
      .pipe(
        map((searchRes: KMSSearchResults<HashtagInfo>) =>
          searchRes.results
            .map(result => result.document)
            .map(val => MapDoc.docToHashInfo(val))
        )
      );
  }

  public searchRelatedHashtagAndStrategicTag(query: string): Observable<HashtagInfo[]> {
    let currentDate = (new Date()).getFullYear();
    query = query?.replace(/^#/, "");
    const params = {
      filter: `((typeId eq 5) and isPrivate eq false  and isDeprecated eq false and referenceYear/any(r: r eq ${currentDate}))
       or (typeId eq 1 and isPrivate eq false and isDeprecated eq false)
       and search.ismatch('${Utilities.escapingNames(query)}', 'name', 'full', 'all')`,
      includeTotalResultCount: false,
      searchFields:["name"],
      top: 10
    };
    return this.searchSvc
      .query<HashtagInfo>("Hashtags", query, params)
      .pipe(
        map((searchRes: any) =>
          searchRes.results
            .map(result => result.document)
            // .filter(document => this.isCurrentYear(document))
            .map(val => MapDoc.docToHashInfo(val))
        )
      );
  }

  public updateNetworkRelatedHashtag(
    networkHastagId: string,
    hashtagIds: string[]
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `portal/api/Hashtag/RelatedHashtag/${networkHastagId}/update`,
      hashtagIds
    );
  }

  public updateIKRelatedHashtag(
    ImpactingKnowledgeId: string,
    hashtagIds: string[]
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `portal/api/Hashtag/RelatedHashtag/${ImpactingKnowledgeId}/update`,
      hashtagIds
    );
  }

  public updateHashtagRelatedHashtag(
    hashtagId: string,
    relatedHashtagIds: string[]
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `portal/api/Hashtag/RelatedHashtag/${hashtagId}/update`,
      relatedHashtagIds
    );
  }

  public updateNetworkStrategicTag(
    networkHashtagId: string,
    strategicTagIds: string[]
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `portal/api/Hashtag/StrategicTag/${networkHashtagId}/update`,
      strategicTagIds
    );
  }

  public getHashtagInfoById(hashtagId: string): Observable<HashtagInfo> {
    if (this.userSvc.isPrivatePage) {
      return this.httpClient.get<HashtagInfo>(
        "external/api/network/Hashtag/" + hashtagId
      );
    } else {
      return this.httpClient.get<HashtagInfo>(
        "portal/api/Hashtag/" + hashtagId
      );
    }
  }

  public getHashtagStatusInfoById(hashtagId: string): Observable<number> {
  
      return this.httpClient.get<number>(
        "portal/api/Hashtag/status/" + hashtagId
      );   
  }

  public getHashTagFollower(networkId: string): Observable<UserInfo[]> {
    return this.httpClient.get<UserInfo[]>(
      `portal/api/Hashtag/${networkId}/followers`
    );
  }

  public getHashTagBadge(
    hashtagId: string,
  ): Observable<HashtagBadge> {
    return this.httpClient.get<HashtagBadge>(
      `portal/api/Hashtag/badge/${hashtagId}`
    );
  }

  public getRelatedHashtags(id: string) {
    return this.httpClient.get<HashtagInfo[]>(
      `portal/api/hashtag/RelatedHashtag/${id}`
    );
  }

  public getDefaultHashtagAvatar(): Observable<string> {
    return this.defaultAvatar$;
  }

  public getDefaultHashtagThumbnail(): Observable<string> {
    return this.defaultThumbnailAvatar$;
  }

  public getStrategicTagByYear(
    years: number[],
    networkId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `portal/api/hashtag/strategic/${networkId}/get/years`,
      years
    );
  }

  public getStrategicTagBadgeByYear(
    year: number,
    hashtagsId: string[]
  ): Observable<any> {
    return this.httpClient.post(
      `portal/api/hashtag/badge/strategic/${year}`,
      JSON.stringify(hashtagsId)
    );
  }

  public saveDescription(description: string, hashtagId: string) {
    return this.httpClient.post(
      `portal/api/hashtag/${hashtagId}/description/set`,
      JSON.stringify(description)
    );
  }

  public uploadHashtagAvatar(
    file: File,
    sourcePostId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `portal/api/hashtag/${encodeURIComponent(sourcePostId)}/avatar/upload`,
      file
    );
    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `hashtag/${encodeURIComponent(sourcePostId)}/avatar/upload`,
    //   formData
    // );
  }

  public searchGenericAndSpecialHashtag(
    query: string
  ): Observable<HashtagInfo[]> {
    const params = {
      filter: "((typeId eq 1 or typeId eq 6) and isPrivate eq false)",
      includeTotalResultCount: false,
      searchFields: ["name"],
      top: 10
    };
    return this.searchSvc
      .query<HashtagInfo>("hashtags" as any, query, params)
      .pipe(
        map((searchRes: KMSSearchResults<HashtagInfo>) =>
          searchRes.results.map(result => result.document)
        )
      );
  }

  searchStrategicTags(query: string) {
    const currentYear = new Date().getFullYear();
    const params = {
      filter: `typeId eq 5 and isPrivate eq false and referenceYear/any(t: t eq ${currentYear})`,
      searchFields: ["name"],
      limit: 10
    };
    return this.searchSvc
      .query<HashtagInfo>("hashtags" as any, query, params)
      .pipe(
        map((searchRes: KMSSearchResults<HashtagInfo>) =>
          searchRes.results.map(result => result.document)
        )
      );
  }

  // getRelatedPost(id: string) {
  //   const params = {
  //     filter: `hashtagsIds/any(t: t eq '${id}')`,
  //     limit: 10,
  //     orderby: ["created desc"]
  //   };
  //   return this.searchSvc
  //     .queryRaw<any>("Posts", "", params)
  //     .pipe(
  //       map((searchRes: KMSSearchResults<any>) =>
  //         searchRes.results.map(result => result.document)
  //       )
  //     );
  // }

  deprecateHashtag(hashtagId: string): Observable<ITransaction> {
    return this.httpClient.post<ITransaction>(
      `portal/api/hashtag/${hashtagId}/deprecate`,
      null
    );
  }

  searchEvenDeprecatedHashtag(index: IndexName, query: string, params?: any) {
    return this.searchSvc.query(index, query, params);
  }

  searchRelatedUsers(hashtagId: string, params?: any) {
    params = {
      filter: `skillsIds/any(t:t eq '${hashtagId}')`
    };
    return this.searchSvc.queryRaw("Users", "", params).pipe(
      catchError(() => {
        return of([]);
      }),
      map((searchRes: KMSSearchResults<any>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToUserInfo(val))
      )
    );
  }

  searchCandidateHashtag(query: string): Observable<KMSSearchResults<Booking>> {
    return this.searchSvc.query<Booking>("Bookings", query);
  }
}
