import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "telephoneNumber"
})
export class TelephoneNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      if ((/^(\+|\d)/g ).test(value)) {
        return value.replace(/^(\+)|\D/g, "$1");
      } else {
        return value.slice(1).replace(/^(\+)|\D/g, "$1");
      }
    } else {
      return value;
    }
  }
}
