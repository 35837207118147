import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from "dayjs";
import * as advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

@Pipe({
  name: "amDateFormat"
})
export class AmDateFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return "";
    }
    return dayjs(value).format(args);
  }
}
