import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { ConfigService } from "@ngx-config/core";

@Injectable({
  providedIn: "root"
})
export class UploadService {
  httpClient: any;
  constructor(private readonly config: ConfigService) {}

  public uploadImage(url: string, file: File): Observable<string> {
    const formData = new FormData();
    formData.append("files", file);
    return from(
      fetch(`${this.config.getSettings().apiUrlPrefix}${url}`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: "Bearer " + localStorage.getItem("adal.idtoken")
        }
      }).then(res => res.json())
    );
  }

  public uploadFile(url: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append("files", file);
    return from(
      fetch(`${this.config.getSettings().apiUrlPrefix}${url}`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: "Bearer " + localStorage.getItem("adal.idtoken")
        }
      }).then(res => res.json())
    );
  }
}
