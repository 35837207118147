import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClickWaitDirective } from "./click-wait.directive";

const DECLARATIONS = [ClickWaitDirective];

const IMPORTS = [CommonModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class ClickWaitModule {}
