import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, AfterViewInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "kms-webinars-widget-tab",
  templateUrl: "./webinars-widget-tab.component.html",
  styleUrls: ["./webinars-widget-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WebinarsWidgetTabComponent implements OnInit, AfterViewInit {

  @Input()
    webinars: any[];
  @Input()
    tabType: string;

  constructor(
    private readonly cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.cd.markForCheck();
  }

}
