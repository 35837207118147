import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "src/app/features/core/core.module";
import { WidgetDateComponent } from "./widget-date.component";
const DECLARATIONS = [
    WidgetDateComponent
];
const IMPORTS = [
    CommonModule,
    CoreModule
];
@NgModule({
declarations: [...DECLARATIONS],
imports: [...IMPORTS],
exports: [...IMPORTS, ...DECLARATIONS]
})
export class WidgetDateModule {}
