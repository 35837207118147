import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";
import { SearchService } from "src/app/services/search.service";
import { RoleTypes, LookUpString, Ranking } from "src/app/shared/entities";
import { Observable } from "rxjs";
import { GamificationService } from "src/app/services/gamification.service";
import { tap, map } from "rxjs/operators";
import { sortBy } from "lodash";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: "kms-ranking",
  templateUrl: "./ranking.component.html",
  styleUrls: ["./ranking.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RankingComponent implements OnInit, AfterViewInit {
  ROLES = RoleTypes;
  roles = [
    new LookUpString("Community", [
      this.ROLES.CoPFacilitator,
      this.ROLES.CoPCoFacilitator,
      this.ROLES.BUFocalPoint,
      this.ROLES.KnowledgeOwner
    ]),
    new LookUpString("K.O.", [
      this.ROLES.KnowledgeOwner
    ]),
    new LookUpString("Facilitators", [
      this.ROLES.CoPFacilitator,
      this.ROLES.CoPCoFacilitator
    ]),
    new LookUpString("Focal Points", [this.ROLES.BUFocalPoint])
  ];

  periods = [
    new LookUpString("January", 1),
    new LookUpString("February", 2),
    new LookUpString("March", 3),
    new LookUpString("April", 4),
    new LookUpString("May", 5),
    new LookUpString("June", 6),
    new LookUpString("July", 7),
    new LookUpString("August", 8),
    new LookUpString("September", 9),
    new LookUpString("October", 10),
    new LookUpString("November", 11),
    new LookUpString("December", 12)
  ];

  // years = [];

  currentMonth = new Date().getMonth() + 1;
  currentYear = new Date().getFullYear();

  loadingIndicator = false;

  ranking$: Observable<Ranking[]>;

  rankingCommunity$: Observable<Ranking[]>;
  rankingKO$: Observable<Ranking[]>;
  rankingFacilitators$: Observable<Ranking[]>;
  rankingFocalPoints$: Observable<Ranking[]>;

  role: LookUpString;
  period: LookUpString;
  year: number;

  noDataFoundErrorMessage = "No items found!";

  currentTab = "Community";


  isMobile = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    private readonly cd: ChangeDetectorRef,
    private readonly gameSvc: GamificationService,
    private readonly configSvc: ConfigurationService
  ) {}

  ngOnInit() {
    window.requestIdleCallback(() => {
      this.loadingIndicator = true;
      this.period = this.periods.find(x => x.value === this.currentMonth);
      this.year = this.currentYear;

      this.getCommunityRanking();

      this.cd.markForCheck();
    }, { timeout: 500 });
  }

  ngAfterViewInit(): void {
    this.el.nativeElement
      .querySelector(".nav")
      .classList.add(
        "nav-tabs-transparent",
        "indicator-primary",
        "nav-tabs-full",
        "nav-tabs-4"
      );
  }

  private getCommunityRanking() {
    this.rankingCommunity$ = this.gameSvc
      .getRankingNotInRole(5, this.year, this.period.value, this.roles[0].value)
      .pipe(
        tap(x => {
          this.loadingIndicator = false;
          this.cd.markForCheck();
        }),
        map(list => sortBy(list, "totalScore").reverse())
      );
  }

  private getKORanking() {
    this.rankingKO$ = this.gameSvc
      .getRankingInRole(5, this.year, this.period.value, this.roles[1].value)
      .pipe(
        tap(x => {
          this.loadingIndicator = false;
          this.cd.markForCheck();
        }),
        map(list => sortBy(list, "totalScore").reverse())
      );
  }

  private getFacilitatorsRanking() {
    this.rankingFacilitators$ = this.gameSvc
      .getRankingInRole(5, this.year, this.period.value, this.roles[2].value)
      .pipe(
        tap(x => {
          this.loadingIndicator = false;
          this.cd.markForCheck();
        }),
        map(list => sortBy(list, "totalScore").reverse())
      );
  }

  private getFocalPointsRanking() {
    this.rankingFocalPoints$ = this.gameSvc
      .getRankingInRole(5, this.year, this.period.value, this.roles[3].value)
      .pipe(
        tap(x => {
          this.loadingIndicator = false;
          this.cd.markForCheck();
        }),
        map(list => sortBy(list, "totalScore").reverse())
      );
  }

  tabClick(curTab: string) {
    this.currentTab = curTab;
    switch (curTab) {
      case "Community":
        if (this.rankingCommunity$ == null) {
          this.getCommunityRanking();
        }
        break;
      case "Facilitators":
        if (this.rankingFacilitators$ == null) {
          this.getFacilitatorsRanking();
        }
        break;
      case "KO":
        if (this.rankingKO$ == null) {
          this.getKORanking();
        }
        break;
      case "Focal Points":
        if (this.rankingFocalPoints$ == null) {
          this.getFocalPointsRanking();
        }
        break;
    }
  }
}
