import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kms-widget-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
