import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input
} from "@angular/core";
import {
  UserNotification,
  EntityType,
  NetworkType
} from "src/app/shared/entities";
import { Router } from "@angular/router";

@Component({
  selector: "kms-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit {
  constructor(private router: Router) {}
  @Input() notifications: UserNotification[];

  ngOnInit() {}

  goToNotification(notification: UserNotification) {
    switch (notification.sourceObjectType) {
      case EntityType.Challenge:
        this.router.navigate(["/challenge", notification.sourceObjectId]);
        break;
      case EntityType.Post:
        this.router.navigate(["/conversation", notification.sourceObjectId]);
        break;
      case EntityType.Hashtag:
        this.router.navigate(["/hashtag", notification.sourceObjectId]);
        break;
      case EntityType.KnowledgeNugget:
        this.router.navigate(["/ik", notification.sourceObjectId]);
        break;
      case EntityType.Idea:
        this.router.navigate(["/idea", notification.sourceObjectId]);
        break;
      case EntityType.Network:
        switch (notification.source.type) {
          case NetworkType.CoP:
            this.router.navigate([
              "/network",
              notification.sourceObjectId,
              "cop"
            ]);
            break;
          case NetworkType.BusinessUnit:
            this.router.navigate([
              "/network",
              notification.sourceObjectId,
              "bu"
            ]);
            break;
          case NetworkType.StrategicTag:
            this.router.navigate(["/hashtag", notification.sourceObjectId]);
            break;
          case NetworkType.SpecialTag:
            this.router.navigate(["/specialtag", notification.sourceObjectId]);
            break;
          case NetworkType.External:
            this.router.navigate([
              "/external-network",
              notification.sourceObjectId
            ]);
            break;
          default:
            break;
        }
        break;
      case EntityType.SuccessStory:
        this.router.navigate([
          "/success-story/view",
          notification.sourceObjectId
        ]);
        break;
      case EntityType.User:
        this.router.navigate(["/profile", notification.sourceObjectId]);
        break;
      case EntityType.Webinar:
        this.router.navigate([
          "/webinars/calendar/video/",
          notification.sourceObjectId
        ]);
        break;
      case EntityType.Booking:
        this.router.navigate([
          "/webinars/calendar/",
          notification.sourceObjectId
        ]);
        break;
    }
  }

  getFormattedNotificationMessage(n: any) {
    if (n && n.message) {
      if (n.sourceUser && n.sourceUser.displayName) {
        return n.message.replace(/{{Author}}/g, n.sourceUser.displayName);
      }
      return n.message;
    }
    return "";
  }
}
