import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { NotificationService } from "src/app/services/notification.service";
@Component({
  selector: "kms-error-notification",
  templateUrl: "./error-notification.component.html",
  styleUrls: ["./error-notification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorNotificationComponent implements OnInit {
  constructor(private notificationSvc: NotificationService) {}

  ngOnInit() {
    if (this.notificationSvc.lastNotification$) {
      // this.notificationSvc.lastNotification$
      //   .pipe(filter(m => !!m && m.level === "error"));
      // WORKS!!
      // .subscribe(m => {
      //   alert(m.message);
      //   console.log(m.message);
      // });
    }
  }
}
