import { NgModule, ErrorHandler } from "@angular/core";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ConfigLoader, ConfigModule } from "@ngx-config/core";
import { DragulaModule } from "ng2-dragula";
import { SnackbarModule } from "ngx-snackbar";
import {
  ApplicationInsightsModule,
  AppInsightsService,
} from "@markpieszak/ng-application-insights";
import { environment } from "src/environments/environment";
import { NgxPermissionsModule } from "ngx-permissions";
import { CoreModule } from "./features/core/core.module";
import { CustomInterceptor } from "./shared/http-interceptor";
import { KMSErrorsHandler } from "./shared/error-handler";
import {
  AuthenticationGuard,
  MsAdalAngular6Module,
} from "microsoft-adal-angular6";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ConfigHttpLoader } from "@ngx-config/http-loader";
import {
  DisclaimerGuard,
  RedirectGuard,
  GuestGuard,
  WebinarGuestGuard,
  LeleGuard,
  ExternalUserGuard,
} from "./shared/guards";
import { HambugerMenuModule } from "./features/hambuger-menu/hambuger-menu.module";
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AlertModule } from "ngx-bootstrap/alert";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ModalModule } from "ngx-bootstrap/modal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StarRatingModule } from "angular-star-rating";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { DeviceDetectorModule } from "ngx-device-detector";
import { CookieConsentModule } from "@mcs/cookie-consent";
import { TechPubModule } from "./pages/tech-pub/tech-pub.module";
import { OnDestroyComponent } from "./shared/on-destroy.component";

export function configFactory(http: HttpClient): ConfigLoader {
  return new ConfigHttpLoader(
    http,
    `config/config.${window["__KMS_ENV"]}.json`
  );
}
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
};

export function getAdalConfig() {
  return {
    tenant: environment.authenticationOptions.tenant,
    clientId: environment.authenticationOptions.clientId,
    endpoints: environment.authenticationOptions.endpoints,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl:
      environment.authenticationOptions.navigateToLoginRequestUrl,
    cacheLocation: environment.authenticationOptions.cacheLocation,
  };
}

@NgModule({
  declarations: [AppComponent, OnDestroyComponent],
  entryComponents: [],
  imports: [
    // Angular
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    // NgxBootstrap
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    // Others
    CookieConsentModule,
    SnackbarModule.forRoot(),
    SwiperModule,
    DragulaModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    ConfigModule.forRoot({
      provide: ConfigLoader,
      useFactory: configFactory,
      deps: [HttpClient],
    }),

    ApplicationInsightsModule.forRoot({
      instrumentationKeySetLater: true,
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    MsAdalAngular6Module.forRoot(getAdalConfig),
    NgxPermissionsModule.forRoot(),
    StarRatingModule.forRoot(),

    // KMS
    CoreModule,
    HambugerMenuModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    { provide: ErrorHandler, useClass: KMSErrorsHandler },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    AppInsightsService,
    AuthenticationGuard,
    DisclaimerGuard,
    RedirectGuard,
    GuestGuard,
    LeleGuard,
    WebinarGuestGuard,
    ExternalUserGuard,
    { provide: BsDropdownConfig, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
