import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Ranking } from "../shared/entities";
import { map } from "rxjs/operators";
import { UserService } from "./user.service";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class GamificationService {
  myScore$ = new BehaviorSubject<any>(null);
  scoreById$: Observable<any>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly userSvc: UserService
  ) {}

  public getMyScore() {
    const today = new Date();
    return this.httpClient
      .get<any>(
        `portal/api/gamification/badge/${
          this.userSvc.userId
        }/${today.getFullYear()}`
      )
      .subscribe(score => this.myScore$.next(score));
  }

  public getScoreById(userId: string) {
    const today = new Date();
    return this.httpClient.get<any>(
      `portal/api/gamification/badge/${userId}/${today.getFullYear()}`
    );
  }

  public getRanking(top: number, year: number, month: number) {
    return this.httpClient.get<Ranking[]>(
      `portal/api/gamification/${
        this.userSvc.userId
      }/ranking/${top}/${year}/${month}`
    );
  }

  public getRankingInRole(
    top: number,
    year: number,
    month: number,
    role: number[]
  ) {
    return this.httpClient.post<Ranking[]>(
      `portal/api/gamification/ranking/inrole/${top}/${year}/${month}`,
      role
    );
  }

  public getRankingNotInRole(
    top: number,
    year: number,
    month: number,
    role: number[]
  ) {
    return this.httpClient.post<Ranking[]>(
      `portal/api/gamification/ranking/notinrole/${top}/${year}/${month}`,
      role
    );
  }

  public getAnnualRankingInRole(top: number, year: number, role: number[]) {
    return this.httpClient.post<Ranking[]>(
      `portal/api/gamification/ranking/inrole/${top}/${year}`,
      role
    );
  }

  public getAnnualRankingNotInRole(top: number, year: number, role: number[]) {
    return this.httpClient.post<Ranking[]>(
      `portal/api/gamification/ranking/notinrole/${top}/${year}`,
      role
    );
  }

  public getAnnualScore(userId: string, year: number) {
    return this.httpClient.get<any>(
      `portal/api/gamification/scores/${userId}/${year}`
    );
  }

  public getMonthlyScore(userId: string, year: number, month: number) {
    return this.httpClient.get<any>(
      `portal/api/gamification/scores/${userId}/${year}/${month}`
    );
  }
}
