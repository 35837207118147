import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FollowButtonComponent } from "./follow-button.component";
import { ClickWaitModule } from "../click-wait/click-wait.module";

const DECLARATIONS = [FollowButtonComponent];
const IMPORTS = [CommonModule, ClickWaitModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS],
})
export class FollowButtonModule {}
