import { Injectable } from "@angular/core";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { environment } from "src/environments/environment";
import { ConfigService } from "@ngx-config/core";
import { SnackbarService } from "ngx-snackbar";
import { UserService } from "./user.service";
import { Guid } from "../shared/utils";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  constructor(
    private readonly svc: AppInsightsService,
    private readonly config: ConfigService,
    private readonly snackbarSvc: SnackbarService,
    private readonly userSvc: UserService,
    private readonly router: Router
  ) {
    // https://www.npmjs.com/package/@markpieszak/ng-application-insights
    // svc.config = {
    //   instrumentationKey: this.config.getSettings().instrumentationKey,
    //   // Used to fix log on ik visit. If we use storage, it send duplicated event.
    //   // https://stackoverflow.com/questions/54929563/azure-application-insights-shows-the-same-event-at-identical-timestamps-multiple
    //   isStorageUseDisabled: true
    // };
    this.logPageViews();
    // then make sure to initialize and start-up app insights
    // svc.init();
  }
  private logPageViews() {
    this.router.events
      .pipe(filter(val => val instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
        this.trackPage(val.url);
      });
  }
  warning(message: string) {
    this.svc.trackTrace(message, this.context, 2);
    if (!environment.production) {
      console.warn(message);
    }
  }

  information(message: string) {
    this.svc.trackTrace(message, this.context, 1);
    if (!environment.production) {
      // tslint:disable no-console
      console.info(message);
    }
  }

  verbose(message: string) {
    this.svc.trackTrace(message, this.context, 0);
    if (!environment.production) {
      // console.log(message);
    }
  }

  error(err: Error, message: string = null) {
    this.svc.trackTrace(err.message, this.context, 3);
    if (this.config.getSettings().showErrorDetails) {
      this.snackbarSvc.add({
        msg: err.message,
        background: "red",
        action: { text: null }
      });
    }
    if (!environment.production) {
      console.error(err.message);
    }
  }

  event(name: string, content?: any, sourceId?: string) {
    const currentUserId = this.userSvc.userId;
    this.svc.trackEvent(name, {
      kmsEventId: Guid.newGuid(),
      sourceUserId: sourceId || currentUserId,
      targetUserId: null,
      skipPoints: 0,
      ...content,
    });
  }

  eventMultiple(name: string, contents: any[]) {
    const kmsEventId = Guid.newGuid();
    const currentUserId = this.userSvc.userId;
    if (contents.length > 0) {
      contents.forEach(content =>
        this.svc.trackEvent(name, {
          kmsEventId,
          sourceUserId: currentUserId,
          targetUserId: null,
          skipPoints: 0,
          ...content,
        })
      );
    }
    // Commented because an empty event is useless. If there are NOT contents, notifications are not fired.
    // else {
    //   this.svc.trackEvent(name, {
    //     kmsEventId,
    //     sourceUserId: currentUserId
    //   });
    // }
  }

  private get context() {
    return {
      userId: this.userSvc.userId,
    };
  }

  public setCurrentUser(
    authenticatedUserUpn: string,
    accountId: string,
    storeInCookie = false
  ) {
    this.svc.setAuthenticatedUserContext(
      authenticatedUserUpn,
      accountId,
      storeInCookie
    );
  }
  public trackPage(url: string) {
    this.svc.trackPageView(url);
  }
}
