import { Component, Inject, OnInit } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { Router } from "@angular/router";
@Component({
    selector: 'kms-bottom-sheet',
    templateUrl: 'bottom-sheet.component.html',
  })
export class BottomSheetComponent implements OnInit{
    constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>, 
                private readonly router: Router,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data) {}
                
    itemsList = [];

    ngOnInit(): void {
        //console.log( "DATI: " + JSON.stringify(this.data.children));
        this.itemsList = this.data.children;
    }
  
    openLink(event: MouseEvent): void {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }

    navigate(link){
      this._bottomSheetRef.dismiss();
      this.router.navigateByUrl(link);
    }
}