import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import * as dayjs from "dayjs";
import { KMSObject } from "src/app/shared/entities";
import { Utilities } from "src/app/shared/utils";

@Component({
    selector: "kms-webinars-list",
    templateUrl: "./webinars-list.component.html",
    styleUrls: ["./webinars-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
  })
  export class WebinarsListComponent implements OnInit{
  
    @Input() webinars: any[];
    @Input() tabType: string;

    KMSObject = KMSObject;
    externalSpeakers: string[] = [];
  
    constructor(
      private readonly cd: ChangeDetectorRef
    ) { }
  
    ngOnInit() {
    }
  
    ngAfterViewInit() {
      this.cd.markForCheck();
    }

    getLink(webinar) {
        if (
          webinar.status === "Published" ||
          webinar.status === "Requested"
        ) {
          return `/webinars/list/video/${webinar.id}`;
        } else {
          return `/webinars/calendar/${webinar.id}`;
        }
    }

    getInternalSpeakers(webinar) {
        const internalSpeakers = [];
        webinar.internalSpeakers.forEach(value => {
          internalSpeakers.push(value);
        });
    
        return internalSpeakers.slice(0, 4);
    }

    getExternalSpeakers(webinar) {
        const externalSpeakers = [];
        webinar.externalSpeakers.forEach(value => {
          externalSpeakers.push(value);
        });
    
        return externalSpeakers.slice(0, 4 -  webinar.internalSpeakers.length);
    }

    getDate(webinar) {
      const date = Utilities.adjustDateUtcToMilan(webinar.date);
      return dayjs(date).format("MMM Do HH:mm");
    }

    getWebinarStatusBadgeColor(status, visibility) {
      switch (status) {
        case "Pending":
          return "pending";
        // case "PreApproved":
        //   return visibility === "Private"
        //     ? "preapproved-private"
        //     : "preapproved-public";
        case "Confirmed":
          return "confirmed";
        default:
          return "confirmed";
      }
    }

    getBookingStatus(status, visibility) {
      // switch (status) {
      //   case "PreApproved":
      //     return visibility === "Private"
      //       ? "Booked-Private"
      //       : "Booked-Public";
      //   default:
      //     return status;
      // }
      return status;
    }
    
    getStatusIcon(webinar) {
      if (dayjs(webinar.date).isBefore(dayjs(new Date()), "day")) {
        return "fas fa-check";
      } else {
        return "fas fa-calendar-alt";
      }
    }

    getDays(webinar) {
      return dayjs(Utilities.adjustDateLocalToMilan(webinar.date)).format("MMM Do");
    }

    getHours(webinar) {
      return dayjs(Utilities.adjustDateLocalToMilan(webinar.date)).format("HH:mm");
    }
  }