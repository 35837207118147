import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HamburgerComponent } from "./hamburger/hamburger.component";
import { WidgetsModule } from "../widgets/widgets.module";

const DECLARATIONS = [HamburgerComponent];

const IMPORTS = [CommonModule, WidgetsModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class HambugerMenuModule {}
