import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { ConfigService } from "@ngx-config/core";
import { timeout, catchError, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { WelcomeExperienceService } from "../services/first-access.service";
import { ConfigurationService } from "../services/configuration.service";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  longTimeoutCalls = ["post"];
  veryLongTimeoutCalls = [
    "portal/api/attachments/upload/",
    "portal/api/attachments/uploadthroughsa/",
    "portal/api/attachments/uploadthroughsacomplete/",
    "portal/api/attachments/uploadthroughsacompleteend/",
    "/attachments/upload",
    "portal/api/newsfeed/post",
  ];
  retryCalls = [
    "welcome/api/user/status",
    "portal/api/configuration/assets/images/config",
  ];
  constructor(
    private readonly adalSvc: MsAdalAngular6Service,
    private readonly firstAccessService: WelcomeExperienceService,
    private readonly config: ConfigService,
    private readonly appConfigSvc: ConfigurationService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Skip for initial config loading
    req = this.setHeaders(req);
    const matchedUrls = this.longTimeoutCalls.filter((r) => {
      const regex: RegExp = new RegExp(r);
      return regex.test(req.url);
    });
    const verySlowMatchedUrls = this.veryLongTimeoutCalls.filter((r) => {
      const regex: RegExp = new RegExp(r);
      return regex.test(req.url);
    });
    const retryMatchedUrls = this.retryCalls.filter((r) => {
      const regex: RegExp = new RegExp(r);
      return regex.test(req.url);
    });

    let timeoutValue =
      verySlowMatchedUrls && verySlowMatchedUrls.length
        ? 300000
        : matchedUrls && matchedUrls.length
        ? 60000
        : 60000;

    //console.warn("Using Timeout "+timeoutValue+" for URL "+req.url);

    return next.handle(req).pipe(
      timeout(timeoutValue),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              if (this.adalSvc.userInfo && !( error.url.includes("prod-165")|| error.url.includes("prod-239")|| error.url.includes("prod-212") || error.url.includes("prod-114") )) {
                return this.adalSvc.acquireToken(document.location.origin).pipe(
                  switchMap(() => {
                    req = this.setHeaders(req);
                    return next.handle(req).pipe(timeout(timeoutValue));
                  }),
                  catchError((err) => {
                    // console.log("error", err);
                    return throwError(err);
                  })
                );
              } else {
                return throwError(error);
              }
            case 403: {
              // if (error.url.includes("user/status")) {
              this.firstAccessService.isFirstAccessActivated.next(true);
              this.firstAccessService.isLoaded.next(true);
              this.appConfigSvc.assetsConfig.next([null, null]);
              this.router.navigate(["/deny-access"]);
              // } else {
              //   this.appConfigSvc.refreshApp.next(true);
              //   this.router.navigate(["/"]);
              // }

              break;
            }
            case 500: {
              if (error.url.includes("api/search/query/Default") && !req.body.item1) {
                console.log("Empty query on search")
                return of(null);
              }
            }
            default:
              if (retryMatchedUrls && retryMatchedUrls.length) {
                req = this.setHeaders(req);
                return next.handle(req).pipe(
                  timeout(timeoutValue),
                  catchError((err) => {
                    this.firstAccessService.errorLoading.next(true);
                    this.firstAccessService.isFirstAccessActivated.next(true);
                    this.firstAccessService.isLoaded.next(true);
                    this.appConfigSvc.assetsConfig.next([null, null]);
                    return throwError(err);
                  })
                );
              } else {
                return throwError(error);
              }
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // this.adalSvc.logout();
    return next.handle(req);
  }

  private setHeaders(req: HttpRequest<any>): HttpRequest<any> {
    // Initial config request must NOT be intercepted
    // OCCHIO A STA PARTE, VA TESTATA PER BENE
    if (req.method === "POST" && req.url.includes("prod-165")) return req; //lab o dev
    if (req.method === "POST" && req.url.includes("prod-239")) return req; // test
    if (req.method === "POST" && req.url.includes("prod-212")) return req; // pre
    if (req.method === "POST" && req.url.includes("prod-114")) return req; // prod

    if (!/config\//.test(req.url)) {
      // const baseUrl = "https://pre-kms.eni.com/in/kms/pre/";
      const baseUrl = this.config.getSettings().apiUrlPrefix;

      const setHeaders = {
        authorization: `Bearer ${this.adalSvc.accessToken}`,
      };

      // Set Content-Type header only for plain objects
      if (
        req.method === "POST" &&
        !(req.body instanceof File) &&
        !(req.body instanceof FormData)
      ) {
        setHeaders["content-type"] = "application/json";
      }
      req = req.clone({
        url: !/https:\/\//.test(req.url) ? baseUrl + req.url : req.url,
        setHeaders,
      });
    }
    return req;
  }
}
