import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  Network,
  StickyPost,
  NavLink,
  NetworkInfo,
  HashtagInfo,
  UserInfo,
  NetworkType,
  AttachmentType,
  Attachment,
  ImageEntityTypes,
  ImageType,
  MapDoc,
  Hashtag
} from "../shared/entities";
import { Observable, of } from "rxjs";
import { map, catchError, filter, delay } from "rxjs/operators";
import { UploadService } from "./upload.service";
import { KMSSearchResults, SearchService } from "./search.service";
import { ConfigurationService } from "./configuration.service";
import { ExternalNetworkService } from "src/app/services/external-network.service";

@Injectable({
  providedIn: "root"
})
export class NetworkService {
  private defaultAvatar$: Observable<string>;
  private defaultThumbnailAvatar$: Observable<string>;
  private defaultNavlink$: Observable<string>;
  private allNetworks:NetworkInfo[]=[];

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configSvc: ConfigurationService,
    private readonly uploaderSvc: UploadService,
    private readonly searchSvc: SearchService,
    private readonly externalSvc : ExternalNetworkService
  ) {
    this.configSvc.assetsConfig.pipe(filter(r => r != null)).subscribe(() => {
      this.defaultAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Network,
        ImageType.Avatar,
        `portal/api/network/avatar/default/get`
      );

      this.defaultThumbnailAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Network,
        ImageType.Cover,
        `portal/api/network/background/default/get`
      );

      this.defaultNavlink$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.NavLink,
        ImageType.Generic,
        `portal/api/network/navlink/default/get`
      );
    });
  }


  public getAllNetworks():NetworkInfo[]{
      return this.allNetworks;
  }

  public setAllNetworks(networks:NetworkInfo[]):void{
    this.allNetworks = networks;
  }

  public getAllNetworksHashtags():HashtagInfo[]{
    let hashtags : HashtagInfo[] = [];
    this.allNetworks.forEach( n => hashtags.push(n.hashtag));
    return hashtags;
  }

  public getPrivateNetworks():Observable<any>{
    return this.httpClient.get<NetworkInfo>('portal/api/network/networks/type/'+5);
  }

  public getExternalPrivateNetworksIds(): Observable<string[]> {
    return this.getPrivateNetworks().pipe(
      map((networks: NetworkInfo[]) => {
        return networks.map(n => n.networkId)
      })
    )
  }


  public searchNetwork(
    query: string,
    params = null
  ): Observable<NetworkInfo[]> {
    if (!params) {
      params = {
        filter: `status eq 'Published' and typeId ne ${NetworkType.External}`,
        includeTotalResultCount: false,
        top: 10
      };
    }

    return this.searchSvc.query("Networks", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<NetworkInfo>) =>
        searchRes.results
          ? searchRes.results
              .map(result => result.document)
              .map(val => MapDoc.docToNetInfo(val))
          : []
      )
    );
  }

  public searchPrivateNetwork(
    query: string
  ): Observable<NetworkInfo[]> {
    // NetworkType: Private
    return this.externalSvc.getExternalNetworkWithFilter(query);
  }

  public searchNetworkByType(
    query: string,
    networkType: NetworkType
  ): Observable<NetworkInfo[]> {

    if(networkType === NetworkType.CoPAndPrivate)
    {
      const params = {
        filter: `typeId eq ${NetworkType.CoP} and status eq 'Published'`,
        includeTotalResultCount: false,
        top: 10
      };

      return this.searchSvc.queryWithExternal(query, params).pipe(
        catchError(() => of([])),
        map((searchRes: KMSSearchResults<NetworkInfo>) =>
          searchRes.results
            ? searchRes.results
                .map(result => result.document)
                .map(val => MapDoc.docToNetInfo(val))
            : []
        )
      );
    }
    else
    {

      const params = {
        filter: `typeId eq ${networkType} and status eq 'Published'`,
        includeTotalResultCount: false,
        top: 10
      };

      return this.searchSvc.query("Networks", query, params).pipe(
        catchError(() => of([])),
        map((searchRes: KMSSearchResults<NetworkInfo>) =>
          searchRes.results
            .map(result => result.document)
            .map(val => MapDoc.docToNetInfo(val))
        )
      );
    }
  }

  public getNetworkCOP(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/cops");
  }

  public getNetworkCOPInternalUser(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/cops/internal");
  }

  public getNetworkBU(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/bus");
  }
  public getNetworkBUInternalUser(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/bus/internal");
  }

  public getNetworkSpecialTag(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/specialtags");
  }

  public getNetworkSpecialTagInternalUser(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/specialtags/internal");
  }

  public getNetworkById(networkId: string): Observable<Network> {
    return this.httpClient.get<Network>("portal/api/network/" + networkId);
  }

  public getNetworksAssociatedToRelatedTags(
    hashtagId: string,
    getMyselfNetwork = false
  ): Observable<Network[]> {
    return this.httpClient.get<Network[]>(
      `portal/api/network/GetNetworksAssociatedToRelatedTags/${hashtagId}/${getMyselfNetwork}`
    );
  }

  public getPrivateNetworksFromUser(
  ): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>(
      `portal/api/network/PrivateFromUser`
    );
  }

  public saveNetworkStickyPost(
    networkId: string,
    stickyPosts: StickyPost[]
  ): Observable<any> {
    return this.httpClient.post(
      `portal/api/network/${networkId}/stickyposts/Save`,
      stickyPosts
    );
  }

  public saveNavLinks(
    networkId: string,
    title: string,
    stickyPosts: NavLink[]
  ): Observable<any> {
    return this.httpClient.post(`portal/api/network/${networkId}/navlinks`, {
      item1: title,
      item2: stickyPosts
    });
  }

  public getNetworkStickyPost(networkId: string): Observable<StickyPost[]> {
    return this.httpClient.get<StickyPost[]>(
      `portal/api/network/${networkId}/stickyposts`
    );
  }

  public addNetworkNavLink(
    navLInk: NavLink,
    networkId: string
  ): Observable<NavLink> {
    return this.httpClient.post<NavLink>(
      `portal/api/network/${networkId}/navlinks/add`,
      navLInk
    );
  }

  public removeNetworkNavLink(
    navLinkId: string,
    networkId: string
  ): Observable<any> {
    return this.httpClient.post<any>(
      `portal/api/network/${networkId}/navlinks/${navLinkId}/remove`,
      null
    );
  }

  public uploadNetworkAvatar(
    file: File,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `portal/api/network/${networkId}/avatar/upload`,
      file
    );
    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `portal/api/network/${networkId}/avatar/upload`,
    //   formData
    // );
  }

  public uploadNetworkBackground(
    file: File,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `portal/api/network/${networkId}/background/upload/`,
      file
    );
    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `portal/api/network/${networkId}/background/upload/`,
    //   formData
    // );
  }

  public getDefaultNetworkAvatar(): Observable<string> {
    return this.defaultAvatar$;
  }

  public getNetworkDefaultBackground(): Observable<any> {
    return this.defaultThumbnailAvatar$;
  }

  public saveAbstract(abstract: string, networkId: string) {
    return this.httpClient.post(
      `portal/api/network/${networkId}/abstract/save`,
      JSON.stringify(abstract)
    );
  }

  public getNetworkStrategicTag(networkId: string): Observable<HashtagInfo[]> {
    return this.httpClient.get<HashtagInfo[]>(
      `portal/api/network/${networkId}/strategicTags`
    );
  }

  public getNetworkOurTag(networkId: string): Observable<HashtagInfo[]> {
    return this.httpClient.get<HashtagInfo[]>(
      `portal/api/network/${networkId}/ourTags`
    );
  }

  public getNetworkByHashtagId(hashtagId: string) {
    return this.httpClient.get<Network>(
      `portal/api/network/hashtag/${hashtagId}`
    );
  }

  public getNetworkByHashtagName(hashtagName: string) {
    return this.httpClient.get<Network>(
      `portal/api/network/hashtagName/${hashtagName}`
    );
  }

  public getNetworksByHashtags(
    hashtags: HashtagInfo[]
  ): Observable<NetworkInfo[]> {
    return this.httpClient.post<NetworkInfo[]>(
      `portal/api/network/FromHashtags`,
      hashtags
    );
  }

  public AddUserToCoreTeam(networkId: string, userId: string): Observable<any> {
    return this.httpClient.post<any>(
      `portal/api/network/${networkId}/coreteam/add/${userId}`,
      null
    );
  }

  public RemoveUserToCoreTeam(
    networkId: string,
    userId: string
  ): Observable<any> {
    return this.httpClient.post<any>(
      `portal/api/network/${networkId}/coreteam/remove/${userId}`,
      null
    );
  }

  public getCoreTeamByNetworkId(networkId: string): Observable<UserInfo[]> {
    return this.httpClient.get<UserInfo[]>(
      `portal/api/network/${networkId}/coreteam`
    );
  }

  public uploadNavlinkImage(
    file: File,
    navlinkId: string,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `portal/api/network/${networkId}/navlink/${navlinkId}/upload/`,
      file
    );
  }

  public uploadNavlinkAttachment(
    file: File,
    navlinkId: string,
    networkId: string
  ): Observable<Attachment[]> {
    return this.uploaderSvc.uploadFile(
      `portal/api/attachments/upload/${
        AttachmentType.NavLink
      }/${networkId}/item/${navlinkId}`,
      file
    );
  }

  public deleteNavlinkAttachment(
    navlinkId: string,
    networkId: string,
    attachmentId: string
  ): Observable<any> {
    return this.httpClient.delete(
      `portal/api/attachments/${
        AttachmentType.NavLink
      }/${networkId}/item/${navlinkId}/${attachmentId}`
    );
  }

  public getDefaultNavLinkImage(): Observable<string> {
    return this.defaultNavlink$;
  }

  public saveNetworkKo(users: UserInfo[], networkId: string): Observable<any> {
    return this.httpClient.post(
      `portal/api/network/${networkId}/koUsers/save`,
      users
    );
  }

  public saveCoreTeamNetwork(
    userId: string[],
    networkId: string
  ): Observable<any> {
    return this.httpClient.post(
      `portal/api/network/${networkId}/coreTeam/save`,
      userId
    ).pipe(
      delay(1500)
    );
  }

  public saveFacilitatorCoFacilitator(
    users: UserInfo[],
    networkId: string,
    isFacilitator: boolean
  ) {
    return isFacilitator
      ? this.httpClient.post(
          `portal/api/network/${networkId}/SaveFacilitators`,
          users
        ).pipe(
          delay(1500)
        )
      : this.httpClient.post(
          `portal/api/network/${networkId}/SaveCoFacilitators`,
          users
        ).pipe(
          delay(1500)
        );
  }

  public getHashtagById(networkId: string){
    return this.httpClient.get(`portal/api/network/getHastahById/${networkId}`);
  }
}
