import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, of, forkJoin } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  User,
  UserBadge,
  Activity,
  UserInfo,
  HashtagInfo,
  PeoplePickerUserRestriction,
  IkInfo,
  UserCategory,
  ImpactingKnowledgeType,
  GuestBadge,
  ImageEntityTypes,
  ImageType,
  MapDoc,
  ExternalUser,
  NetworkType,
} from "../shared/entities";
import { NgxPermissionsService } from "ngx-permissions";
import { map, filter, catchError, tap, switchMap } from "rxjs/operators";
import { NotificationService } from "./notification.service";
import { UploadService } from "./upload.service";
import { SearchService, KMSSearchResults } from "./search.service";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { Guid, Utilities } from "src/app/shared/utils";
import { ConfigurationService } from "./configuration.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userProfileSubject = new BehaviorSubject<User>(null);
  private externalUserProfileSubject = new BehaviorSubject<ExternalUser>(null);

  private profilesSubject = new BehaviorSubject<User[]>([]);
  profile$: Observable<User[]> = this.profilesSubject.asObservable();

  public isPrivateSection = new BehaviorSubject<boolean>(false);
  private isPrivate = false;

  private externalUser$: BehaviorSubject<ExternalUser> =
    new BehaviorSubject<ExternalUser>(null);
  externalUser: ExternalUser;

  private defaultAvatar$: Observable<string>;
  private defaultThumbnailAvatar$: Observable<string>;
  private defaultBackground$: Observable<string>;

  public followAction = new BehaviorSubject<void>(null);

  prefLanguage: string;
  // used for check if user is null because is not already loaded or is null because don't have a profile
  public isLoaded = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly httpClient: HttpClient,
    private readonly permissionsService: NgxPermissionsService,
    private readonly notificationSvc: NotificationService,
    private readonly uploaderSvc: UploadService,
    private readonly searchSvc: SearchService,
    private readonly configSvc: ConfigurationService,
    private readonly adalSvc: MsAdalAngular6Service // private readonly logger: LoggerService
  ) {
    this.followAction.subscribe(() => {
      this.GetMyProfile();
    });
    // this.notificationSvc.lastNotification$
    //   .pipe(
    //     filter(n => !!n),
    //     filter(n => /follow|unfollow/i.test(n.Command))
    //   )
    //   .subscribe(() => this.GetMyProfile());

    this.isPrivateSection.subscribe((value) => (this.isPrivate = value));

    this.configSvc.assetsConfig.pipe(filter((r) => r != null)).subscribe(() => {
      this.defaultAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.User,
        ImageType.Avatar,
        `portal/api/user/avatar/default/get`
      );
      this.defaultThumbnailAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.User,
        ImageType.Thumbnail,
        `portal/api/user/avatar/default/thumbnail/get`
      );

      this.defaultBackground$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.User,
        ImageType.Cover,
        `portal/api/user/background/default/get`
      );
    });
    // this.getExternalUser(this.userProfile.upn)
    // console.log(this.userProfileSubject.value)
  }
  setProfiles(profiles: User[]) {
    this.profilesSubject.next(profiles);
  }

  public getUserProfiles(userIds: string[]): Observable<User[]> {
    const observables = userIds.map((userId) => {
      const url = "portal/api/user/Profile/" + userId;
      return this.httpClient.get<User>(url).pipe(
        catchError((error) => {
          console.error(`Error fetching profile for user ${userId}:`, error);

          return of(null);
        })
      );
    });

    return forkJoin(observables).pipe(
      switchMap((profiles) => {
        // Filtra i profili validi (non null)
        const validProfiles = profiles.filter((profile) => profile !== null);
        return of(validProfiles);
      })
    );
  }

  public GetUserProfile(userId) {
    return this.httpClient.get<User>("portal/api/user/Profile/" + userId);
  }

  public async GetUserInfoFor(userId: string): Promise<UserInfo[]> {
    //alert("getUserInfo "+userId);
    return this.httpClient
      .post<UserInfo[]>(`portal/api/user/Profiles`, '["' + userId + '"]')
      .toPromise();
  }

  public getExternalUserByUPN(userUPN: string): Observable<ExternalUser> {
    return this.httpClient.get<ExternalUser>(
      `portal/api/user/externalusers/` + userUPN
    );
  }

  public async GetUserInfosFor(userIds: string[]): Promise<UserInfo[]> {
    //alert("getUserInfos "+userIds);
    let userIdsString = "";
    if (userIds.length > 0) {
      userIdsString += '"' + userIds[0] + '"';
    }
    for (let i = 1; i < userIds.length; i++) {
      userIdsString += ',"' + userIds[i] + '"';
    }
    return this.httpClient
      .post<UserInfo[]>(`portal/api/user/Profiles`, "[" + userIdsString + "]")
      .toPromise();
  }

  public getExternalUserNotPromise(upn: string): Observable<ExternalUser> {
    return this.getExternalUserByUPN(upn).pipe(
      map((val) => {
        const externalUser = new ExternalUser(val);
        this.externalUserProfileSubject.next(externalUser);
        return externalUser;
      })
    );
  }

  public getPrivateNetworks(): Observable<string[]> {
    return this.getExternalUserNotPromise(this.userProfile.upn).pipe(
      map((externalUser: ExternalUser) => {
        return externalUser.networks
          .filter((n) => n.item2 === NetworkType.External)
          .map((n) => n.item1);
      })
    );
  }

  public async getExternalUser(upn: string) {
    let val = await this.getExternalUserByUPN(upn).toPromise();
    let externalUser = new ExternalUser(val);
    this.externalUserProfileSubject.next(externalUser);
    return val;
  }

  public async GetMyProfile() {
    // use force to make call for get always new value
    // in app.component the call to profile MUST be always with new value
    // or with the welcome experience the value , after profile creation, is always null

    let val = await this.httpClient
      .get<User>("portal/api/user/Profile")
      .toPromise();
    val = new User(val);
    this.isLoaded.next(true);

    if (val) {
      this.prefLanguage = val.preferences.preferredLanguage;
    }

    this.userProfileSubject.next(val);
    if (
      this.userProfile.isExternalConsultant == true &&
      this.isGuest == false
    ) {
      await this.getExternalUser(this.userProfile.upn);
    }

    return val;
  }

  public setUserProfile(val: User) {
    this.isLoaded.next(true);
    if (val) {
      this.prefLanguage = val.preferences.preferredLanguage;
    }
    this.userProfileSubject.next(val);

    return val;
  }

  get isPrivatePage() {
    return this.isPrivate;
  }

  get isGuest() {
    return (
      //this.userProfile &&
      this.userCategory === UserCategory.Guest
    );
  }

  get myProfile() {
    return this.userProfileSubject.asObservable();
  }

  public uploadUserAvatar(
    file: File,
    UPN: string,
    userId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `portal/api/user/${userId}/avatar/upload`,
      file
    );
  }

  public getDefaultUserAvatar(): Observable<string> {
    return this.defaultAvatar$;
  }

  public getDefaultUserThumbnail(): Observable<string> {
    return this.defaultThumbnailAvatar$;
  }

  public getUserBadge(userId: string): Observable<UserBadge> {
    return this.httpClient.get<UserBadge>(`portal/api/user/Badge/${userId}`);
  }

  public getExternalUserBadge(): Observable<GuestBadge> {
    return this.httpClient.get<GuestBadge>(`external/api/guest/Badge`);
  }

  public getUserBadgeFull(userId: string): Observable<UserBadge> {
    return this.httpClient.get<UserBadge>(
      `portal/api/user/badge/full/${userId}`
    );
  }

  public getMyBadge(): Observable<UserBadge> {
    return this.httpClient.get<UserBadge>(`portal/api/user/Badge`);
  }

  public getMyActivity(): Observable<Activity[]> {
    return this.httpClient.get<Activity[]>(`portal/api/user/activity`);
  }

  public getUserActivity(userId: string): Observable<Activity[]> {
    return this.httpClient.get<Activity[]>(
      `portal/api/user/activity/${userId}`
    );
  }

  public trackEvent(
    eventName: string,
    others: string,
    id: string
  ): Observable<any> {
    // console.log(eventName+": "+id);
    if (others == null) {
      others = "";
    }
    return this.httpClient.post("portal/api/user/" + id + "/traceevent", {
      item1: eventName,
      item2: others,
    });
  }

  public updateProfile(profile: string): Observable<any> {
    return this.httpClient.post(`portal/api/user/profile/update`, profile);
  }

  public uploadUserBackground(
    file: File,
    UPN: string,
    userId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `portal/api/user/${userId}/background/upload`,
      file
    );
  }

  public getUserDefaultBackground(): Observable<string> {
    return this.defaultBackground$;
  }

  public searchUser(
    query: string,
    restrictions: PeoplePickerUserRestriction = PeoplePickerUserRestriction.None
  ): Observable<UserInfo[]> {
    const params = {
      filter:
        "search.ismatch('" +
        Utilities.escapingNames(query) +
        "', '" +
        "name" +
        "', 'full', 'all') and isDeleted eq false",
      searchFields: ["name", "email", "lastName", "firstName"],
      top: 20,
    };
    const KOparams = {
      filter:
        "isKOEligible eq true and search.ismatch('" +
        Utilities.escapingNames(query) +
        "', '" +
        "name" +
        "', 'full', 'all')",

      searchFields: ["name", "email", "lastName", "firstName"],
    };

    switch (restrictions) {
      case PeoplePickerUserRestriction.None:
        return this.searchSvc.query("Users", query, params).pipe(
          catchError(() => of([])),
          map((searchRes: KMSSearchResults<any>) =>
            searchRes.results
              .map((result) => result.document)
              .map((val) => MapDoc.docToUserInfo(val))
          )
        );
      case PeoplePickerUserRestriction.OnlyKO:
        return this.searchSvc.query("Users", query, KOparams).pipe(
          catchError(() => of([])),
          map((searchRes: KMSSearchResults<any>) =>
            searchRes.results
              .map((result) => result.document)
              .map((val) => MapDoc.docToUserInfo(val))
          )
        );
    }
  }

  public getExternalUsers(speaker: string): Observable<UserInfo[]> {
    const params = {
      facets: ["externalSpeakers"],
      top: 10,
    };

    return this.searchSvc.query("Bookings", speaker, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<any>) =>
        searchRes.facets.externalSpeakers
          .filter((f) => (<string>f.value).includes(speaker) === true)
          .map((value) => new UserInfo({ displayName: value.value }))
      )
    );
  }

  public getConversations(rootPostIds: string[]): Observable<any> {
    return this.httpClient.post(
      "portal/api/newsfeed/conversations",
      rootPostIds
    );
  }

  public logout() {
    this.adalSvc.logout();
  }

  get userProfile$() {
    return this.userProfileSubject.asObservable();
  }

  get userProfile() {
    return this.userProfileSubject.value;
  }

  get externalUserProfile() {
    return this.externalUserProfileSubject.value;
  }

  get isLoaded$() {
    return this.isLoaded.asObservable();
  }

  get userId() {
    return this.userProfileSubject.value
      ? this.userProfileSubject.value.userId
      : null;
  }
  get userUpn() {
    return this.userProfileSubject.value
      ? this.userProfileSubject.value.upn
      : null;
  }
  get userRoles() {
    return this.userProfileSubject.value.roles;
  }

  get userCategory() {
    // to modify when category is populated by back-end
    //  return UserCategory.Guest;
    return this.userProfileSubject.value
      ? this.userProfileSubject.value.category || UserCategory.Employee
      : UserCategory.Employee;
  }

  public improveNewsFeed(
    users: UserInfo[],
    hashs: HashtagInfo[]
  ): Observable<any> {
    return this.httpClient.post<any>(`portal/api/user/bulkfollow`, {
      item1: users,
      item2: hashs,
    });
  }

  public searchIkForUser(query: string, userId: string): Observable<IkInfo[]> {
    //
    // $filter=
    const params = {
      filter: `((involvedPeopleIds/any(m: search.in(m, '${userId}'))) and
        typeId ne ${ImpactingKnowledgeType.Webinar} and
      (
           (typeId eq ${ImpactingKnowledgeType.SuccessStory} and status eq 'Published')
        or (typeId eq ${ImpactingKnowledgeType.Challenge} and status ne 'Created')
        or (typeId ne ${ImpactingKnowledgeType.Challenge} and typeId ne ${ImpactingKnowledgeType.SuccessStory})
      )) or (typeId eq ${ImpactingKnowledgeType.Webinar} and internalSpeakersIds/any(m: search.in(m, '${userId}')))`,
      includeTotalResultCount: false,
      top: 10,
    };

    return this.searchSvc.query("ImpactingKnowledges", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<any>) =>
        searchRes.results
          .map((result) => result.document)
          .map((val) => MapDoc.docToIkInfo(val))
      )
    );
  }

  getPeopleYouMightKnow(): Observable<UserInfo[]> {
    if (this.userProfile) {
      const switcher = this.userProfile.followedPeople.length > 0 ? 1 : 0;
      return this.httpClient.get<UserInfo[]>(
        "portal/api/user/recommend/people/" + switcher
      );
    } else {
      return of([]);
    }
  }

  getHashtagYouMightLike() {
    return this.httpClient.get<HashtagInfo[]>(
      "portal/api/user/recommend/hashtags"
    );
  }

  getRoles(userIds: string[]): Observable<any> {
    return this.httpClient.post("portal/api/User/Profiles", userIds);
  }
}
