import { Pipe, PipeTransform } from "@angular/core";
import { Dictionary } from "lodash";
import { LookUpString } from "src/app/shared/entities";
@Pipe({
  name: "gamAction"
})
export class GamificationActionPipe implements PipeTransform {
  private actionsDictionary: LookUpString[] = [
    new LookUpString("UNKNOWN", 0),

    new LookUpString("Mentioned you", 1),
    new LookUpString("Involved you", 2),
    new LookUpString("New idea for your challenge", 3),
    new LookUpString("Your success story has been published", 4),
    new LookUpString("Voted your KnowledgeNugget/Idea", 5),
    new LookUpString("Liked your post", 51),
    new LookUpString("Upvoted your post", 52),
    new LookUpString("Highlighted your post", 53),
    new LookUpString("Followed you", 6),
    new LookUpString("Replied to your post", 8),
    new LookUpString("Your network is promoting a Knowledge Nugget", 9),
    new LookUpString("Your network is promoting a Challenge", 10),
    new LookUpString("Your network is promoting an Idea", 11),
    new LookUpString("Your network is promoting a Success Story", 12),
    new LookUpString("Opened a new challenge", 13),
    new LookUpString("Reminder of a closing challenge", 14),
    new LookUpString("Closed challenge", 15),
    new LookUpString("Demote of a Knowledge Nugget", 16),
    new LookUpString("Added audience to your post", 17),
    new LookUpString("Voted your poll", 18),
    new LookUpString("Changed results of your challenge", 19),
    new LookUpString("New involved people added in your webinar", 31),
    new LookUpString("Your CoP is added to a webinar", 32),
    new LookUpString("Your BU is added to a webinar", 33),
    new LookUpString("You are added as speaker of a webinar", 34),
    new LookUpString("Your webinar is published", 35),
    new LookUpString("Your webinar is deleted", 36),
    new LookUpString("Your webinar is moved", 37),

    new LookUpString("KM TEAM", 7),
    new LookUpString("WEBINAR TEAM", 30),
    new LookUpString("LIKE", 96),
    new LookUpString("KO VALIDATION", 98),

    new LookUpString("Your webinar for private group is pending approval", 60),
    new LookUpString("New webinar booked", 61),
    new LookUpString("Date webinar change", 62),
    new LookUpString("New published post", 63),
    new LookUpString("New published reply to any post", 64),
    new LookUpString("New attachment", 65),
  ];
  transform(value: any, args?: any): any {
    const val = this.actionsDictionary.find(el => el.value === value)
      ? this.actionsDictionary.find(el => el.value === value).key
      : null;
    return val || value;
  }
}
