import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";
import {
  Webinar,
  BookingStatus,
  WebinarColor
} from "src/app/shared/webinars-entities";
import { KMSObject, UserInfo } from "src/app/shared/entities";
import { WebinarsService } from "src/app/services/webinars.service";
import * as dayjs from "dayjs";
import { Utilities } from "src/app/shared/utils";

@Component({
  selector: "kms-webinars-widget-item",
  templateUrl: "./webinars-widget-item.component.html",
  styleUrls: ["./webinars-widget-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WebinarsWidgetItemComponent implements OnInit {
  @Input()
  webinar: Webinar;
  @Input()
  tabType: string;
  @Input()
  index: number;

  KMSObject = KMSObject;
  externalSpeakers: string[] = [];

  constructor(
    private readonly webinarSvc: WebinarsService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
  }

  get getLink() {
    if (
      this.webinar.status === "Published" ||
      this.webinar.status === "Requested"
    ) {
      return `/webinars/list/video/${this.webinar.id}`;
    } else {
      return `/webinars/calendar/${this.webinar.id}`;
    }
  }

  get getInternalSpeakers() {
    const internalSpeakers = [];
    this.webinar.internalSpeakers.forEach(value => {
      internalSpeakers.push(value);
    });

    return internalSpeakers.slice(0, 5);
  }

  get getExternalSpeakers() {
    const externalSpeakers = [];
    this.webinar.externalSpeakers.forEach(value => {
      externalSpeakers.push(value);
    });

    return externalSpeakers.slice(0, 5 -  this.webinar.internalSpeakers.length);
  }

  get getDate() {
    const date = Utilities.adjustDateUtcToMilan(this.webinar.date);
    return dayjs(date).format("MMM Do HH:mm");
  }

  get getPosition() {
    return this.index === 0 ? "bottom" : "top";
  }

  getStatusIcon(status) {
    if (dayjs(this.webinar.date).isBefore(dayjs(new Date()), "day")) {
      return "fas fa-check";
    } else {
      return "fas fa-calendar-alt";
    }
  }

  getWebinarStatusBadgeColor(status, visibility) {
    switch (status) {
      case "Pending":
        return "pending";
      // case "PreApproved":
      //   return visibility === "Private"
      //     ? "preapproved-private"
      //     : "preapproved-public";
      case "Confirmed":
        return "confirmed";
      default:
        return "confirmed";
    }
  }

  getBookingStatus(status, visibility) {
    // switch (status) {
    //   case "PreApproved":
    //     return visibility === "Private"
    //       ? "Booked-Private"
    //       : "Booked-Public";
    //   default:
    //     return status;
    // }
    return status;
  }
}
