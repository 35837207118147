import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "kms-widget",
  templateUrl: "./wrapper.component.html",
  styleUrls: ["./wrapper.component.scss"]
})
export class WrapperComponent implements OnInit {

  @Input() color = "default";
  @Input() animation: string;

  constructor() {}

  ngOnInit() {}
}
