import { Pipe, PipeTransform } from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Utilities } from "src/app/shared/utils";

@Pipe({
  name: "imageLink"
})
export class ImageLinkPipe implements PipeTransform {
  constructor(private readonly configurationSvc: ConfigurationService) {}

  transform(value: any, args?: any): any {
    return Utilities.replaceToken(value, this.configurationSvc.assetsConfig);
  }
}
