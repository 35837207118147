import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnDestroy
} from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { User, KMSObject, ParentPageForAvatar } from "src/app/shared/entities";
import { NotificationService } from "src/app/services/notification.service";
import { filter, map } from "rxjs/operators";
import { GamificationService } from "src/app/services/gamification.service";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "kms-widget-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit, OnDestroy {
  User: User;
  myScore: any;

  subscription: Subscription;

  constructor(
    private userSvc: UserService,
    private cd: ChangeDetectorRef,
    private notificationSvc: NotificationService,
    public gamSvc: GamificationService
  ) {}

  type = KMSObject.User;
  ngOnInit() {
    this.gamSvc.myScore$.subscribe(score => (this.myScore = score));
    this.userSvc.userProfile$.subscribe(user => {
      this.User = user;
      this.cd.markForCheck();
    });

    // this.subscription = this.notificationSvc.lastNotification$
    //   .pipe(
    //     filter(x => !!x),
    //     filter(x => /User/i.test(x.Command))
    //   )
    //   .subscribe(() => {
    //     this.userSvc.GetMyProfile();
    //   });
    window.requestIdleCallback(() => {
      if (this.userSvc && this.userSvc.userProfile) {
        this.gamSvc.getMyScore();
      }
    }, { timeout: 500 });
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

  get parentPageForAvatar() {
    return ParentPageForAvatar;
  }
}
