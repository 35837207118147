import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MiniUserComponent } from "./mini-user/mini-user.component";
import { AvatarViewModule } from "../avatar-view/avatar-view.module";
import { RouterModule } from "@angular/router";

const IMPORTS = [CommonModule, AvatarViewModule, RouterModule];
const DECLARATIONS = [MiniUserComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS],
})
export class MiniUserModule {}
