import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";

@Component({
  selector: "kms-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent implements OnInit {
  @Input()
  color: string;
  @Input()
  textColor = "dark";
  @Input()
  counter: number;
  @Input()
  title: string;
  @Input()
  description: string;
  @Input()
  iconClass: string;
  @Input()
  secondaryIconClass: string;

  constructor() {}

  ngOnInit() {}
}
