import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import {
  Disclaimer,
  NetworkInfo,
  WelcomeExperienceStatus,
  User,
  LookUpString,
  HashtagInfo,
  UserInfo,
  WelcomeExperienceOptionalStep,
} from "../shared/entities";
import { ConfigurationService } from "./configuration.service";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { UploadService } from "./upload.service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class WelcomeExperienceService {
  public welcomeListThreshold = 100;

  public userEmail = new BehaviorSubject<string>(null);

  private currentDisclaimerSubject = new BehaviorSubject<Disclaimer>(null);

  public prevStep: BehaviorSubject<WelcomeExperienceOptionalStep> =
    new BehaviorSubject(null);

  public isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );

  public errorLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public networkSelection: BehaviorSubject<NetworkInfo[]> = new BehaviorSubject(
    []
  );

  public userSelection: BehaviorSubject<UserInfo[]> = new BehaviorSubject([]);

  public hashtagSelection: BehaviorSubject<HashtagInfo[]> = new BehaviorSubject(
    []
  );

  constructor(
    private httpClient: HttpClient,
    private configurationService: ConfigurationService,
    private readonly authSvc: MsAdalAngular6Service,
    private uploaderSvc: UploadService
  ) {}

  public isFirstAccessActivated: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public welcomeExperienceStatus: BehaviorSubject<WelcomeExperienceStatus> =
    new BehaviorSubject(null);

  public welcomeExperienceStatusProfile: BehaviorSubject<any> =
    new BehaviorSubject(null);

  public get displayName() {
    return this.authSvc.LoggedInUserName;
  }

  get UPN() {
    return this.authSvc.LoggedInUserEmail;
  }

  get currentDisclaimer$() {
    return this.currentDisclaimerSubject.asObservable();
  }

  public get email() {
    return this.userEmail.value;
  }

  public get profile() {
    return this.authSvc.userInfo.profile;
  }

  public get firstName() {
    return this.authSvc.userInfo.profile.given_name;
  }
  public get lastName() {
    return this.authSvc.userInfo.profile.family_name;
  }

  public getOldNetworkFollowed(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>(
      `portal/api/user/oldkms/networks`
    );
  }

  public getOldUserFollowed(): Observable<UserInfo[]> {
    return this.httpClient.get<UserInfo[]>(`portal/api/user/oldkms/people`);
  }

  public getOldHashtagFollowed(): Observable<HashtagInfo[]> {
    return this.httpClient.get<HashtagInfo[]>(
      `portal/api/user/oldkms/hashtags`
    );
  }

  public checkWelcomeExperience(): Observable<[WelcomeExperienceStatus, User]> {
    return this.httpClient
      .get<[WelcomeExperienceStatus, User]>(`welcome/api/user/status`)
      .pipe(tap((res) => this.welcomeExperienceStatusProfile.next(res)));
  }

  public createProfile(userProfile: User) {
    return this.httpClient.post("welcome/api/user/create", userProfile);
  }

  public acceptDisclaimer() {
    return this.httpClient.post("welcome/api/user/disclaimer/accept", null);
  }

  public DownloadDisclaimer(): Observable<string> {
    return this.httpClient.get<string>(`welcome/api/user/disclaimer/get`);
  }

  public async getUserDisclaimer() {
    const val = await this.httpClient
      .get<Disclaimer>("welcome/api/user/disclaimer")
      .toPromise();

    this.currentDisclaimerSubject.next(val);

    return val;
  }

  public getProfile(): Observable<User> {
    return this.httpClient.get<User>(`welcome/api/user/profile`);
  }

  public getLanguages(): Observable<LookUpString[]> {
    return this.httpClient.get<LookUpString[]>(`welcome/api/user/languages`);
  }

  public welcomeExperienceCompleted() {
    return this.httpClient.post(`welcome/api/user/complete`, null);
  }

  public uploadUserWelcomeAvatar(file: File, UPN: string): Observable<string> {
    return this.uploaderSvc.uploadImage("welcome/api/user/avatar/upload", file);
  }

  public getSuggestedFollowers(hashtagIds: string[]): Observable<string[]> {
    return this.httpClient.post<string[]>(
      `welcome/api/User/suggest/followers`,
      hashtagIds
    );
  }

  public getSuggestedHashtag(userIds: string[]): Observable<string[]> {
    return this.httpClient.post<string[]>(
      `welcome/api/User/suggest/hashtags`,
      userIds
    );
  }
}
