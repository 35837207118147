import { Component, OnInit } from "@angular/core";

@Component({
  selector: "kms-footbar",
  templateUrl: "./footbar.component.html",
  styleUrls: ["./footbar.component.scss"]
})
export class FootbarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
