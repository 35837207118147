import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  LookUpString,
  ChoiceDefinition,
  ChoiceType,
  ChoiceField,
  StickyPost,
  EvaluationFieldCategory,
  ITransaction,
  ContactType,
  Contact,
  AttachmentType,
  PortalConfig,
  DefaultImage,
  ImageEntityTypes,
  ImageType,
  Attachment
} from "../shared/entities";
import { Observable, BehaviorSubject, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { fileURLToPath } from "url";
import { chunk } from "lodash-es";

@Injectable({
  providedIn: "root"
})
export class ConfigurationService {
  isMobile: BehaviorSubject<boolean> = new BehaviorSubject(null);
  isMobileOrSmallDesktop: BehaviorSubject<boolean> = new BehaviorSubject(null);
  isIpad: BehaviorSubject<boolean> = new BehaviorSubject(null);

  assetsConfig: BehaviorSubject<[string, string]> = new BehaviorSubject(null);

  refreshChart: BehaviorSubject<boolean> = new BehaviorSubject(null);

  refreshApp: BehaviorSubject<boolean> = new BehaviorSubject(null);

  canEdit = new BehaviorSubject<boolean>(false);

  instrumentationKey = "";
  pushKey = "";
  bookingMinDaysToBeAutoApproved  = 9;
  defaultImages: DefaultImage[] = null;

  constructor(private httpClient: HttpClient) {
    // create a intervall for refresh key every 30 minute, expiration time for token is 1 hr


    setInterval(() => {
      // this.getAssetsServiceConfig();
      this.getPortalServiceConfig();
    }, 1800000);
  }

  canEditImage(permission:boolean):void{
    this.canEdit.next(permission);
  }

  public getLanguages(): Observable<LookUpString[]> {
    return this.httpClient.get<LookUpString[]>(
      `portal/api/configuration/languages`
    );
  }

  public getAllEvaluationDefinitions(): Promise<Array<ChoiceDefinition[]>> {
    return Promise.all([
      this.getActiveChoiceDefinition(EvaluationFieldCategory.AddedValueType)
        .pipe(map(list => this.reorderChoiceDefinition(list)))
        .toPromise(),
      this.getActiveChoiceDefinition(EvaluationFieldCategory.InnovationLevel)
        .pipe(map(list => this.reorderChoiceDefinition(list)))
        .toPromise(),
      this.getActiveChoiceDefinition(EvaluationFieldCategory.MostlyImpactedArea)
        .pipe(map(list => this.reorderChoiceDefinition(list)))
        .toPromise()
    ]);
  }

  // ChoiceField/IKEvaluationFields  -> choicedef
  public getActiveChoiceDefinition(
    type: EvaluationFieldCategory
  ): Observable<ChoiceDefinition[]> {
    return this.httpClient.get<ChoiceDefinition[]>(
      `portal/api/configuration/IKEvaluationFields/${type}`
    );
  }

  public getChoiceField(type: ChoiceType): Observable<ChoiceField> {
    return this.httpClient.get<ChoiceField>(
      `portal/api/configuration/ChoiceField/${type}`
    );
  }

  public getStickyPosts(networkId: string): Observable<StickyPost[]> {
    return this.httpClient.get<StickyPost[]>(
      `portal/api/configuration/stickypost/${networkId}`
    );
  }

  public saveStickyPost(
    networkId: string,
    stickyPosts: StickyPost[]
  ): Observable<any> {
    return this.httpClient.post(
      `portal/api/configuration/stickypost/${networkId}`,
      stickyPosts
    );
  }

  public like(faqId: string) {
    return this.httpClient.post<ITransaction>(
      `portal/api/configuration/FAQ/${faqId}/like`,
      null
    );
  }
  public unlike(faqId: string) {
    return this.httpClient.post<ITransaction>(
      `portal/api/configuration/FAQ/${faqId}/unlike`,
      null
    );
  }

  public getContactsBytype(type: ContactType) {
    return this.httpClient.get<Contact[]>(
      `portal/api/configuration/Contacts/Type/${type}`
    );
  }
  public getDefaultContacts() {
    return this.httpClient.get<Contact[]>(
      `portal/api/configuration/Contacts/Default`
    );
  }

  // public async getAssetsServiceConfig() {
  //   const val = await this.httpClient
  //     .get<[string, string]>(`portal/api/configuration/assets/images/config`)
  //     .toPromise();

  //   this.assetsConfig.next(val);

  //   return val;
  // }

  public async getPortalServiceConfig() {
    const val = await this.httpClient
      .get<PortalConfig>(`portal/api/configuration/assets/portal/config`)
      .toPromise();

    if (val) {
      this.instrumentationKey = val.instrumentationKey;
      this.pushKey = val.pushKey;
      this.defaultImages = val.defaultImages;
      this.assetsConfig.next(val.storageImage);
      this.bookingMinDaysToBeAutoApproved  = val.bookingMinDaysToBeAutoApproved || 9;
    }

    return val ? val.storageImage : null;
  }

  public getDefaultImage(
    entityType: ImageEntityTypes,
    imageType: ImageType,
    url: string
  ): Observable<string> {
    const defaultImage = this.defaultImages
      ? this.defaultImages.find(
          r => r.entityType === entityType && r.imageType === imageType
        )
      : null;
    if (defaultImage) {
      return of(defaultImage.url).pipe(shareReplay());
    }
    return this.httpClient.get<string>(url).pipe(shareReplay());
  }

  public createCDNImageUrl(imageUrl: string, isSmallVersion = false) {
    if (imageUrl) {
      const regex = /[^:\/]\/(.*)/g;
      const m = regex.exec(imageUrl);
      let url = m[1];
      const smallRegExp = /\/?avatar\/(.+)/i;

      if (isSmallVersion) {
        url = url.replace(smallRegExp, "/avatar/small_$1");
      }
      const assetKey = this.assetsConfig.value["item2"];
      const assetDomain = this.assetsConfig.value["item1"];
      return `${assetDomain}/${url}${assetKey}`;
    } else {
      return null;
    }
  }
  public deleteAttachment(
    type: AttachmentType,
    objectId: string,
    attachmentIds: string[]
  ) {
    return this.httpClient.post(
      `portal/api/attachments/delete/${type}/${objectId}`,
      attachmentIds
    );
  }

  async uploadAttachment(url: string, attachments: File[], inchunk: boolean = false) {

    //alert("upload in chunk? "+inchunk);

    if(!inchunk){

      const payload = new FormData();
      attachments.forEach(x => payload.append("file", x));
      return await this.httpClient.post<ITransaction>(url, payload).toPromise();

    }else{

      if(url.indexOf("uploadthroughsa")==-1){
        alert("Invalid KMS API call.\nThe chunked upload is only enabled on API Attachments/uploadthroughsa");
        return;
      }
      /*
      //preliminary check on size
      for(let i=0; i<attachments.length; i++){

        let file = attachments[i];

        if(file.size > 500 * 1024 * 1024){
          alert("The maximum upload size is currently set to 500MB.\nPlease add only files within that size.");
          return "TooBigFile";
        }
      }*/

      let toReturn :  Attachment[] = [];

      for(let i = 0; i<attachments.length; i++){
        let chunkSize = 20 * 1024 * 1024;//20MB

        let blockIds: string[] = [];
        let file = attachments[i];

        if(chunkSize > file.size){
          chunkSize = file.size;
        }

        //alert("file size is "+file.size + " name "+file.name);
        let generatedTempFileName = null;
        let originalFilename = file.name;
        //TODO remove single quote char from name '

        for(let offset = 0; offset < file.size; offset += chunkSize){
          console.warn("going to split iteration "+offset + " vs "+file.size);
          const chunk = file.slice( offset, offset + chunkSize );
          const payload = new FormData();

          /*
          const data = {
            filename: generatedTempFileName,
            chunk: chunk,
            offset: offset,
            chunkSize: chunkSize
          };*/

          payload.append('data', chunk);
          payload.append("filename", generatedTempFileName);
          payload.append("originalFilename", originalFilename);
          payload.append("offset", ""+offset);
          payload.append("chunkSize", ""+chunkSize);

          var genFileName_blockIdTuple = await this.httpClient.post<any>(url, payload).toPromise();

          //alert(JSON.stringify(genFileName_blockIdTuple));

          generatedTempFileName = genFileName_blockIdTuple.item1;
          var blockId = genFileName_blockIdTuple.item2;

          blockIds.push(blockId);

          //alert("generatedTempFileName = "+generatedTempFileName);
        }

        console.warn("going to complete upload");
        //commit
        var transactionId = await this.httpClient.post<any>(url.replace("uploadthroughsa", "uploadthroughsacomplete"), 
        {
          item1: generatedTempFileName,
          item2 : originalFilename,
          item3: blockIds
        }).toPromise();

        //console.warn("transactionId "+transactionId);
//        await this.notificationSvc
//        .waitForTransactionResult(transactionId)
//        .toPromise();

//        console.warn("transaction completed");

        var reply = null;

        while(reply==null){

            console.warn("going to completeEND upload");
            reply = await this.httpClient.post<Attachment[]>(url.replace("uploadthroughsa", "uploadthroughsacompleteend"), 
              {
                item1: generatedTempFileName,
                item2 : originalFilename
              }).toPromise();
            
              let found = false;

              for(var ii = 0; ii<reply.length; ii++){
                //alert("check if "+reply[ii].name + " = "+originalFilename);
                if(reply[ii].name === originalFilename){
                  found = true;
                  break;
                }
              }

              if(!found)
                reply = null;
        
            await this.sleep(5000);
        }

               //console.warn("returned attachments list");

        toReturn = [...toReturn, ...reply];
      };

      return toReturn;
    }
  }

  
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private reorderChoiceDefinition(choices: ChoiceDefinition[]) {
    return choices
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      })
      .map(c => new ChoiceDefinition(c));
  }
}
