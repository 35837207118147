import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafeHtmlPipe } from "./pipes/trust-html.pipe";
import { EscapeHtmlPipe } from "./pipes/escape-html.pipe";
import { AmFromNowPipe } from "./pipes/am-from-now.pipe";
import { AmDateFormatPipe } from "./pipes/am-format.pipe";
import { GamificationActionPipe } from "./pipes/gamification-action.pipe";
import { ImageLinkPipe } from "./pipes/image-link.pipe";
import { IgnoreHtmlTagsPipe } from "./pipes/ignore-html-tags.pipe";
import { TelephoneNumberPipe } from "./pipes/telephone-number.pipe";

const PIPES = [
  SafeHtmlPipe,
  EscapeHtmlPipe,
  AmFromNowPipe,
  AmDateFormatPipe,
  GamificationActionPipe,
  ImageLinkPipe,
  IgnoreHtmlTagsPipe,
  TelephoneNumberPipe
];
const IMPORTS = [CommonModule];

@NgModule({
  declarations: [...PIPES],
  imports: [...IMPORTS],
  exports: [...PIPES, ...IMPORTS]
})
export class PipesModule {}
