import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from "@angular/core";

@Component({
  selector: "kms-collapse",
  templateUrl: "./collapse.component.html",
  styleUrls: ["./collapse.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CollapseComponent implements OnInit {
  constructor() {}

  @Input()
  isCollapsed = false;

  @Input()
  format = false;

  @Output()
  open = new EventEmitter();

  ngOnInit() {}

  onClick() {
    this.isCollapsed = !this.isCollapsed;

    if (!this.isCollapsed) {
      this.open.emit(null);
    }
  }
}
