import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "kms-hamburger",
  templateUrl: "./hamburger.component.html",
  styleUrls: ["./hamburger.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HamburgerComponent implements OnInit {
  isCollapsed = true;
  constructor() {}

  ngOnInit() {}
}
