import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LayoutService {
  public sm$ = new BehaviorSubject(false);
  public md$ = new BehaviorSubject(false);
  public lg$ = new BehaviorSubject(false);
  public xl$ = new BehaviorSubject(false);
  public current$ = new BehaviorSubject("");

  constructor() {
    [
      { key: "sm", size: "576px", emit: this.sm$ },
      { key: "md", size: "768px", emit: this.md$ },
      { key: "lg", size: "992px", emit: this.lg$ },
      { key: "xl", size: "1200px", emit: this.xl$ }
    ].forEach(({ key, size, emit }) => {
      const mql = window.matchMedia(`(min-width: ${size})`);
      emit.next(mql.matches);
      // this.current$.next(key);
      mql.addListener(e => {
        emit.next(e.matches);
        this.current$.next(key);
      });
    });
  }
}
