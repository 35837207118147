import { Component, OnDestroy } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Component({
  selector: "kms-on-destroy",
  template: "",
  styleUrls: [],
})
export class OnDestroyComponent implements OnDestroy {
  public destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
