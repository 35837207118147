import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, combineLatest, zip, BehaviorSubject, Subject } from "rxjs";
import { map, shareReplay, catchError } from "rxjs/operators";
import { UploadService } from "./upload.service";
import {
  Webinar,
  Booking,
  Chapter,
  VideoInfo,
  InsightsFace,
  TranscriptElement,
  WebinarsMailType,
  InsightsKeywords,
  InsightsTopic,
  WebinarBadge,
  WebinarVideoState,
  WebinarVideoTranscriptEditInfo,
  BookingAssistance,
  BookingAssistanceAvailabilityType,
} from "../shared/webinars-entities";
import { SearchService, KMSSearchResults, IndexName } from "./search.service";
import {
  RoleNameVariable,
  ImageEntityTypes,
  ImageType,
  UserInfo,
  User,
  Participation,
  ITransaction,
} from "../shared/entities";
import { NgxPermissionsService } from "ngx-permissions";
import { UserService } from "./user.service";
import * as dayjs from "dayjs";
import { ConfigurationService } from "./configuration.service";
import { LoggerService } from "./logger.service";
import { ConfigService } from "@ngx-config/core";
import { uniq } from "lodash-es";
import { IkService } from "./ik.service";

@Injectable({
  providedIn: "root",
})
export class WebinarsService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly uploaderSvc: UploadService,
    private readonly ikSvc: IkService,
    private readonly searchSvc: SearchService,
    private readonly permissionSvc: NgxPermissionsService,
    private readonly userSvc: UserService,
    private readonly configSvc: ConfigurationService,
    private readonly loggerSvc: LoggerService,
    private readonly config: ConfigService
  ) {}
    
  public dateDisabled$ :Subject<Date> = new Subject<Date>();


  public getVideo(videoId: string): Observable<any> {
    return this.httpClient
      .get<any>(`webinar/api/ik/webinar/Video/${videoId}`)
      .pipe(catchError((value) => of(console.error(value))));
  }

  public async getVideoList() {
    const response = await this.httpClient
      .get(`webinar/api/webinar/VideoList`)
      .toPromise();
    return response;
  }

  public async getParticipationList(id: string): Promise<Participation[]> {
    const response = await this.httpClient
      .get<Participation[]>(`webinar/api/ik/webinar/${id}/participations`)
      .toPromise();
    return response;
  }

  public async updateParticipationList(list: Participation[]) {
    const response = await this.httpClient
      .post<ITransaction>(`webinar/api/ik/webinar/updateparticipations`, list)
      .toPromise();
    return response;
  }

  public async getVideoZips(): Promise<string[]> {
    return this.httpClient
      .get<string[]>(`webinar/api/ik/webinar/Zips`)
      .toPromise();
  }

  public getWebinarThumbnail(
    thumbnailId: string,
    videoId: string
  ): Observable<any> {
    return this.httpClient.get(
      `webinar/api/ik/webinar/Video/${videoId}/Thumbnail/${thumbnailId}/link`,
      {
        responseType: "text",
      }
    );
  }

  public isDayEnabledForBooking(
    year: number,
    month: number,
    date?: number
  ): Observable<{ item1: string; item2: boolean }[]> {
    return this.httpClient.get<{ item1: string; item2: boolean }[]>(
      `webinar/api/booking/calendar/isBookingEnabled/${year}/${month}` +
        (date ? `/${date}` : "")
    );
  }

  public setDayEnabledForBooking(
    year: number,
    month: number,
    day: number,
    enabled: boolean
  ): Promise<ITransaction> {
    return this.httpClient
      .post<ITransaction>(
        `webinar/api/booking/calendar/setBookingEnabled/${year}/${month}/${day}`,
        enabled
      )
      .toPromise();
  }

  public getWebinarFollowers(webinarId: string): Observable<User[]> {
    return this.httpClient.get<User[]>(
      `webinar/api/ik/webinar/followers/${webinarId}`
    );
  }

  public getBase64Thumb(thumbnailId: string, videoId: string) {
    return `${
      this.config.getSettings().apiUrlPrefix
    }webinar/api/ik/webinar/Video/${videoId}/Thumbnail/${thumbnailId}`;
  }

  public getWebinarsFaceAvatar(imageUrl: string): Observable<string> {
    // return this.httpClient.get(imageUrl);
    return this.httpClient
      .get(imageUrl, {
        responseType: "blob",
      })
      .pipe(
        map((file: File) => URL.createObjectURL(file)),
        shareReplay()
      );
  }

  public getWebinarsAvatar(imageUrl: string) {
    return this.httpClient.get(
      `webinar/api/ik/webinar/avatar/get/image?path=${encodeURIComponent(
        imageUrl
      )}`
    );
  }

  public getWebinarDefaultAvatar() {
    const url = `webinar/api/ik/webinar/avatar/default/get`;

    return this.configSvc.getDefaultImage(
      ImageEntityTypes.Webinar,
      ImageType.Avatar,
      url
    );
  }

  public validateBooking(booking: Booking): Promise<any> {
    return this.httpClient
      .post<string>(`webinar/api/booking/Validate`, booking)
      .toPromise();
  }

  public checkBookingAvailability(
    booking: Booking
  ): Observable<BookingAssistance> {
    // return this.httpClient
    // .post<BookingAssistance>(`webinar/api/booking/Availability`, booking);
    return this.httpClient.post<BookingAssistance>(
      "webinar/api/booking/assistanceAvailability",
      booking
    );
  }

  public checkWebinarAvailability(
    webinar: Webinar
  ): Observable<BookingAssistance> {
    // return this.httpClient
    // .post<BookingAssistance>(`webinar/api/booking/Availability`, booking);
    return this.httpClient.post<BookingAssistance>(
      "webinar/api/ik/webinar/assistanceAvailability",
      webinar
    );
  }

  public createBooking(booking: Booking): Promise<any> {
    return this.httpClient
      .post<string>(`webinar/api/booking/Create`, booking)
      .toPromise();
  }

  public updateBooking(booking: Booking): Promise<any> {
    return this.httpClient
      .post<string>(`webinar/api/booking/Update`, booking)
      .toPromise();
  }

  public updateWebinarInBooking(date: Date): Promise<any> {
    const updatedDate = new Date(date.getTime());
      updatedDate.setHours(updatedDate.getHours() + 4);

    return this.httpClient
      .post<string>(`webinar/api/ik/webinar/Update/pending`, updatedDate)
      .toPromise();
  }

  public updateWebinar(webinar: Webinar): Observable<string> {
    return this.httpClient.post<string>(
      `webinar/api/ik/webinar/update`,
      webinar
    );
  }

  public transcriptEditsTelemetrySave(
    userId: string,
    transcriptEdits: WebinarVideoTranscriptEditInfo,
    transcriptsNumber: number,
    webinarId: string
  ) {
    return this.httpClient.post<KMSSearchResults<any>>(
      `webinar/api/ik/webinar/SaveTranscrptEditTelemetry/${userId}/${transcriptsNumber}/${webinarId}`,
      transcriptEdits
    );
  }

  //the promise should be managed on caller
  public deleteStorageZipFile(zipName: string): Observable<any> {
    // : Promise<any>
    while (zipName.indexOf("/") != -1) {
      zipName = zipName.replace("/", "||");
    }
    //alert(zipName);
    return this.httpClient.delete(
      `webinar/api/ik/webinar/video-drop/${zipName}`
    );
  }

  public deleteWebinar(id: string): Promise<any> {
    return this.httpClient.delete(`webinar/api/ik/webinar/${id}`).toPromise();
  }

  public deleteBooking(id: string): Promise<any> {
    return this.httpClient.delete(`webinar/api/booking/${id}`).toPromise();
  }

  public downloadAttachmentGetUrl(url: string, webinarId: string) {
    return `external/api/webinar/${webinarId}/attachments/${btoa(url)}`;
  }

  public uploadUserAvatar(file: File, id: string): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `webinar/api/ik/webinar/${encodeURIComponent(id)}/avatar/upload`,
      file
    );
  }

  public getEventsByMonth(
    startMonth: string,
    endMonth: string,
    query: string
  ): Promise<[KMSSearchResults<any>, KMSSearchResults<any>]> {
    const filter = `date ge ${startMonth} and date le ${endMonth}`;
    const additionalFilter = `${filter} and status ne 'Locked' and status ne 'Canceled'`;
    return Promise.all([
      this.searchSvc
        .query<Webinar>("Webinars", query, {
          filter: filter,
          searchFields: ["name", "description", "promotingNetworks"],
          top: 150,
          orderby: ["date asc"],
        })
        .toPromise()
        .catch((_) => null),
      this.searchSvc
        .query<Booking>("Bookings", query, {
          filter: additionalFilter,
          searchFields: ["name", "description", "promotingNetworks"],
          top: 150,
          orderby: ["date asc"],
        })
        .toPromise()
        .catch((_) => null),
    ]);
  }

  public getVideoByid(id: string): Promise<Webinar> {
    return (
      this.httpClient
        .get<Webinar>(`webinar/api/ik/webinar/${id}`)
        // .pipe(
        //   map ((val: Webinar) => {
        //     val.chapters = val.chapters.filter(c => !!c.indexed);
        //     return val;
        //   })
        // )
        .toPromise()
    );
  }

  public isDeletedById(id: string): Promise<number> {
    return this.httpClient
      .get<number>(`webinar/api/booking/isDeleted/${id}`)
      .toPromise();
  }

  public isWebinarDeletedById(id: string): Observable<Number> {
    return this.httpClient.get<Number>(
      `webinar/api/ik/webinar/isDeleted/${id}`
    );
  }

  public getBookingById(id: string): Promise<Booking> {
    return this.httpClient
      .get<Booking>(`webinar/api/booking/${id}`)
      .toPromise();
  }

  public getBookingByIdGuest(id: string): Promise<Booking> {
    return this.httpClient
      .get<Booking>(`external/api/webinar/${id}`)
      .toPromise();
  }

  public getVideoNameList(): Promise<Chapter[]> {
    return this.httpClient
      .get<Chapter[]>(`webinar/api/ik/webinar/Video`)
      .toPromise();
  }

  async getMyBookings(): Promise<KMSSearchResults<Booking>> {
    const currDate = new Date();
    const currDateJs = dayjs()
      .year(currDate.getUTCFullYear())
      .month(currDate.getUTCMonth())
      .date(currDate.getUTCDate())
      .hour(currDate.getUTCHours())
      .minute(currDate.getUTCMinutes());
    const filter = `date ge ${currDateJs.format(
      "YYYY-MM-DDTHH:mm:ss"
    )}.000Z and status  ne 'Locked' and status ne 'Canceled' `;
    const userId = this.userSvc.userProfile.userId;

    return this.searchSvc
      .query<Booking>("Bookings", "", {
        filter: `${filter} and (involvedPeopleIds/any(i: i eq '${userId}') or internalSpeakersIds/any(i: i eq '${userId}'))`,
        orderby: ["date"],
        top: 5,
      })
      .toPromise();
  }

  async getWebinarsLiveSoon(): Promise<
    [KMSSearchResults<Webinar>, KMSSearchResults<Booking>]
  > {
    const currDate = new Date();
    const currDateJs = dayjs()
      .year(currDate.getUTCFullYear())
      .month(currDate.getUTCMonth())
      .date(currDate.getUTCDate())
      .hour(currDate.getUTCHours())
      .minute(currDate.getUTCMinutes());
    const filter = `date ge ${currDateJs.format("YYYY-MM-DDTHH:mm:ss")}.000Z`;
    return Promise.all([
      this.searchSvc
        .query<Webinar>("Webinars", "", {
          filter: filter,
          orderby: ["date"],
          top: 5,
          select: [
            "url",
            "logoUrl",
            "name",
            "status",
            "id",
            "internalSpeakers",
            "externalSpeakers",
            "internalSpeakersIds",
            "date",
            "visibility",
          ],
        })
        .toPromise(),
      this.searchSvc
        .query<Booking>("Bookings", "", {
          filter: `${filter} and status eq 'Confirmed'`,
          orderby: ["date"],
          top: 5,
          select: [
            "url",
            "name",
            "status",
            "id",
            "internalSpeakers",
            "externalSpeakers",
            "internalSpeakersIds",
            "date",
            "visibility",
          ],
        })
        .toPromise(),
    ]);
  }

  // public searchExternalSpeakers(indexName: IndexName, speaker: string): Observable<String[]> {
  //   const params = {
  //     facets: ["externalSpeakers"],
  //     top: 10
  //   };

  //  return this.searchSvc.query(indexName, speaker, params).pipe(
  //     catchError(() => of([])),
  //     map((searchRes: KMSSearchResults<any>) =>
  //       searchRes.facets.externalSpeakers.map(value => value.value)
  //     )
  //   );
  // }

  public searchExternalSpeakers(speaker: string): Observable<any> {
    const params = {
      facets: ["externalSpeakers"],
      top: 10,
    };

    if(speaker != null){
      return combineLatest(
        this.searchSvc.query("Bookings", speaker, params).pipe(
          catchError(() => of([])), // empty list on error
          map((searchRes: KMSSearchResults<any>) =>
            searchRes.facets.externalSpeakers.map((value) => value.value)
          ),
          map((externalSpeakers) =>
            externalSpeakers.filter(
              (e) => e.toLowerCase().includes(speaker.toLowerCase()) === true
            )
          )
        ),
        this.searchSvc.query("Webinars", speaker, params).pipe(
          catchError(() => of([])), // empty list on error
          map((searchRes: KMSSearchResults<any>) =>
            searchRes.facets.externalSpeakers.map((value) => value.value)
          ),
          map((externalSpeakers) =>
            externalSpeakers.filter(
              (e) => e.toLowerCase().includes(speaker.toLowerCase()) === true
            )
          )
        )
      );

    }else{
      return of([])
    }

   
  }

  public sendInvites(id: string, type: WebinarsMailType, isPreview: boolean) {
    const payload = {
      item1: id,
      item2: type,
      item3: isPreview,
    };
    return this.httpClient.post(`webinar/api/booking/sendinvites`, payload);
  }

  public sendReminders(id: string, type: WebinarsMailType, isPreview: boolean) {
    const payload = {
      item1: id,
      item2: type,
      item3: isPreview,
    };
    return this.httpClient.post(
      `webinar/api/ik/webinar/sendreminders`,
      payload
    );
  }

  public retrieveInvitedUserEmails(
    id: string,
    type: WebinarsMailType,
    privateMembersFlag: boolean
  ): Observable<Blob> {
    const payload = {
      item1: id,
      item2: type,
      item3: privateMembersFlag,
    };
    return this.httpClient.post(
      `webinar/api/ik/webinar/retrieveInvitedUserEmails`,
      payload,
      {
        responseType: "blob",
      }
    );
  }

  public getWebinarStatistics(webinarId: string): Promise<any> {
    return this.httpClient
      .get(`webinar/api/ik/webinar/statistics/partecipantsCounter/${webinarId}`)
      .toPromise();
  }

  public getWebinarRemoteParticipants(webinarId: string): Promise<any> {
    return this.httpClient
      .get<any>(`webinar/api/ik/webinar/statistics/participants/${webinarId}`)
      .toPromise();
  }

  public getWebinarBUParticipantsList(webinarId: string): Promise<any[]> {
    return this.httpClient
      .get<any[]>(
        `webinar/api/ik/webinar/statistics/BUPartecipantsCount/${webinarId}`
      )
      .toPromise();
  }

  public getWebinarsViewers(webinarId: string): Promise<UserInfo[]> {
    return this.httpClient
      .get<UserInfo[]>(`webinar/api/ik/webinar/Views/People/${webinarId}`)
      .toPromise();
  }

  public getWebinarsBUViewersList(webinarId: string): Promise<any[]> {
    return this.httpClient
      .get<any[]>(`webinar/api/ik/webinar/Views/BUPeopleCount/${webinarId}`)
      .toPromise();
  }

  public async trackView(
    webinarId: string,
    speakers: UserInfo[]
  ): Promise<any> {
    const view = await this.checkView(
      webinarId,
      this.userSvc.userProfile.userId
    );
    if (!view) {
      const eventPayload = uniq([
        ...speakers.map((u) => u.userId),
        this.userSvc.userId,
      ]).map((x) => ({
        targetUserId: x,
      }));
      this.loggerSvc.eventMultiple("ik.webinar.play", eventPayload);

      //this.ikSvc.trackEventWebinar("ik.webinar.play",this.userSvc.userId,null).subscribe(() => {});

      return this.httpClient
        .post<any>(`webinar/api/ik/webinar/SetView/${webinarId}`, {})
        .toPromise();
    }
  }

  public async checkView(webinarId: string, userId: string): Promise<boolean> {
    return this.httpClient
      .get<boolean>(
        `webinar/api/ik/webinar/Views/userviewvideo/${webinarId}/${userId}`
      )
      .toPromise();
  }

  public getViewers(webinarId): Promise<number> {
    return this.httpClient
      .get<number>(`webinar/api/ik/webinar/Views/${webinarId}`)
      .toPromise();
  }

  public updateFace(
    face: InsightsFace,
    videoId: string,
    webinarId: string
  ): Promise<any> {
    return this.httpClient
      .post(`webinar/api/ik/webinar/${webinarId}/Video/${videoId}/face`, face)
      .toPromise();
  }

  public updateSpeaker(
    faces: InsightsFace[],
    videoId: string,
    webinarId: string
  ): Promise<any> {
    return this.httpClient
      .post(`webinar/api/ik/webinar/${webinarId}/${videoId}/speakers`, faces)
      .toPromise();
  }

  public updateTranscript(
    transcript: TranscriptElement[],
    videoId: string,
    webinarId: string
  ): Promise<any> {
    return this.httpClient
      .post(
        `webinar/api/ik/webinar/${webinarId}/Video/${videoId}/transcript`,
        transcript
      )
      .toPromise();
  }

  public publishTranscript(
    webinarId: string,
    videoId: string,
    showTranscript: boolean
  ) {
    return this.httpClient
      .post(
        `webinar/api/ik/webinar/${webinarId}/${videoId}/PublishTranscript/${showTranscript}`,
        null
      )
      .toPromise();
  }

  public updateVideoTranscriptEdits(
    transcriptEdits: WebinarVideoTranscriptEditInfo,
    videoId: string,
    chapterId: string
  ): Promise<any> {
    return this.httpClient
      .post(
        `webinar/api/ik/webinar/Video/${videoId}/Chapter/${chapterId}/transcripteditssave`,
        transcriptEdits
      )
      .toPromise();
  }

  public getChapterVideoTranscriptEdits(
    webinarId: string,
    videoId: string,
    chapterId: string
  ): Promise<WebinarVideoTranscriptEditInfo> {
    return this.httpClient
      .get<WebinarVideoTranscriptEditInfo>(
        `webinar/api/ik/webinar/Video/${videoId}/Chapter/${chapterId}/transcriptedits`
      )
      .toPromise();
  }

  public updateKeywords(
    webinarId: string,
    videoId: string,
    payload: InsightsKeywords[]
  ) {
    return this.httpClient
      .post(`webinar/api/ik/webinar/${webinarId}/${videoId}/keywords`, payload)
      .toPromise();
  }

  public updateTopics(
    webinarId: string,
    videoId: string,
    payload: InsightsTopic[]
  ) {
    return this.httpClient
      .post(`webinar/api/ik/webinar/${webinarId}/${videoId}/topics`, payload)
      .toPromise();
  }

  public setHiddenFaces(webinarId: string, chapterId: string, hiddenFaces) {
    this.httpClient
      .post(
        `webinar/api/ik/webinar/${webinarId}/${chapterId}/hiddenfaces`,
        hiddenFaces
      )
      .toPromise();
  }

  public async canUpdateInsight(videoId: string): Promise<boolean> {
    const result = await this.getVideo(videoId).toPromise();
    if (!result || result.state === WebinarVideoState.PROCESSING) {
      return false;
    }

    return true;
  }

  public getWebinarBadge(id: string): Observable<WebinarBadge> {
    return this.httpClient.get<WebinarBadge>(
      `webinar/api/ik/webinar/badge/${id}`
    );
  }

  private calculatePermission() {
    return Promise.all([
      this.permissionSvc.hasPermission(RoleNameVariable.Admin),
      this.permissionSvc.hasPermission(RoleNameVariable.WebinarTeam),
    ]);
  }
}
