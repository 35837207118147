import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AvatarViewComponent } from "./avatar-view/avatar-view.component";
import { RouterModule } from "@angular/router";

const IMPORT = [CommonModule, TooltipModule, RouterModule];

const DECLARATION = [AvatarViewComponent];

@NgModule({
  imports: [...IMPORT],
  declarations: [...DECLARATION],
  exports: [...IMPORT, ...DECLARATION]
})
export class AvatarViewModule {}
