import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";
import { ContactType, Contact, Role, ParentPageForAvatar } from "src/app/shared/entities";
import { Observable, of } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: "kms-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ContactsComponent implements OnInit, AfterViewInit {
  constructor(
    private el: ElementRef<HTMLElement>,
    private readonly configurationSvc: ConfigurationService,
    private readonly cd: ChangeDetectorRef
  ) {}
  isHelpAlreadyLoaded = false;
  isWebinarAlreadyLoaded = false;
  roles = new Role();
  ParentPageForAvatar = ParentPageForAvatar;
  ContactType = ContactType;
  kmsTeam$: Observable<Contact[]>;
  kmsHelp$: Observable<Contact[]>;
  kmWebinar$: Observable<Contact[]>;
  ngOnInit() {
    this.roles.kmTeam = true;
    window.requestIdleCallback(() => {
      this.kmsTeam$ = this.configurationSvc.getContactsBytype(
        ContactType.KMTeam
      );
      this.cd.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.el.nativeElement
      .querySelector(".nav")
      .classList.add(
        "nav-tabs-transparent",
        "indicator-primary",
        "nav-tabs-full",
        "nav-tabs-3"
      );
  }

  tabClick(tabNumber) {
    switch (tabNumber) {
      case ContactType.KMSHelp:
        if (!this.isHelpAlreadyLoaded) {
          this.kmsHelp$ = this.configurationSvc.getContactsBytype(tabNumber);
          this.isHelpAlreadyLoaded = true;
        }
        return;
      case ContactType.KMTeam:
        return;
      case ContactType.KMWebinar:
        if (!this.isWebinarAlreadyLoaded) {
          this.kmWebinar$ = this.configurationSvc.getContactsBytype(tabNumber);
          this.isWebinarAlreadyLoaded = true;
        }

        return;
    }
  }
}
