import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation
} from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { User, UserCategory, UserInfo } from "src/app/shared/entities";

@Component({
  selector: "kms-mini-user",
  templateUrl: "./mini-user.component.html",
  styleUrls: ["./mini-user.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MiniUserComponent implements OnInit {
  @Input()
  user: UserInfo | User;
  @Input()
  color: string;
  @Input()
  showDisplayName = true;

  @Input()
  showUnit = false;

  @Input()
  canLink = true;

  @Input()
  isKoOwner = false;

  constructor(
    private readonly userSvc: UserService
  ) {}

  ngOnInit() {}
    
  isGuest() {
    return (
      this.userSvc.userProfile &&
      this.userSvc.userCategory === UserCategory.Guest
    );
  }
}
