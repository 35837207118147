import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from "@angular/core";
import * as dayjs from "dayjs";
import { Utilities } from "src/app/shared/utils";

@Component({
  selector: "kms-widget-date",
  templateUrl: "./widget-date.component.html",
  styleUrls: ["./widget-date.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WidgetDateComponent implements OnInit {

  @Input() utcDate: string;
  @Input() color: string;

  date: Date;

  constructor() { }

  ngOnInit() {
    this.date = Utilities.adjustDateLocalToMilan(this.utcDate);
  }

  get getDate() {
    return dayjs(this.date).format("MMM Do");
  }

  get getHours() {
    return dayjs(this.date).format("HH:mm");
  }

}
