import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: ".btn"
})
export class DefocusButtonDirective {
  constructor() {}

  @HostListener("click", ["$event"])
  onClick(ev: Event) {
    (ev.target as HTMLElement).blur();
  }
}
