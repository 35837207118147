import { ErrorHandler, Injectable } from "@angular/core";
import { SnackbarService } from "ngx-snackbar";
import { ConfigService } from "@ngx-config/core";

// See https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a
@Injectable()
export class KMSErrorsHandler implements ErrorHandler {
  constructor(
    private readonly snackbarSvc: SnackbarService,
    private readonly config: ConfigService
  ) {
    window.addEventListener("error", error => {
      if (this.config.getSettings().showErrorDetails) {
        this.snackbarSvc.add({
          msg: error.message,
          background: "red",
          action: { text: null }
        });
      }
    });
  }
  handleError(error: Error) {
    // Do whatever you like with the error (send it to the server?)
    // And log it to the console
    if (error['url'] && error['url'].includes("api/search/query/Default") && error['status'] === 500) {
      return;
    }
    console.error("It happens: ", error);

    if (!/avatar/i.test(error.message)) {
      if (this.config.getSettings().showErrorDetails) {
        this.snackbarSvc.add({
          msg: error.message,
          background: "red",
          action: { text: null }
        });
      }
    }
  }
}
