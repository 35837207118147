import {
    Component,
    OnInit,
    ElementRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    Input
  } from "@angular/core";
  import * as dayjs from "dayjs";
  import { BehaviorSubject, Observable, } from "rxjs";
  import { Challenge, KMSObject } from "src/app/shared/entities";
  import {
    SearchService
  } from "src/app/services/search.service";
  import { ConfigurationService } from "src/app/services/configuration.service";
  
  @Component({
    selector: "kms-challenges-list",
    templateUrl: "./challenges-list.component.html",
    styleUrls: ["./challenges-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
  })
  export class ChallengesListComponent implements OnInit {
    @Input() expiringSoon: Observable<Challenge[]>;
    expiringSoonError = false;
    @Input() expired: Observable<Challenge[]>;
    expiredError = false;
    @Input() mostVoted: Observable<Challenge[]>;
    mostVotedError = false;
    loadingIndicator = false;
  
    noDataFoundErrorMessage = "There are no Challenges so far";
    serverErrorMessage = "An error occurred!";
    
    currentTab = "Open";
    isMobile = false;

    KMSObject = KMSObject;
    isExpiring$ = new BehaviorSubject(false);
  
    constructor(
      private readonly configSvc: ConfigurationService
    ) {}
  
    ngOnInit() {
      this.configSvc.isMobile.subscribe(el => {
        this.isMobile = el;
      });
    }
  
    isBeforeToday(date) {
      const today = dayjs();
      const ref = dayjs(date);
  
      return ref.isBefore(today, "millisecond");
    }

    timeLeft(element) {
      const today = dayjs();
      const endDate = dayjs(element.challengeEndDate);
  
      const days = endDate.diff(today, "day", false);
      const hours = endDate.diff(today, "hour", false);
      const minutes = endDate.diff(today, "minute", false);
  
      if (days) {
        this.isExpiring$.next(false);
        if (days === 1) {
          return days + " day";
        } else {
          return days + " days";
        }
      } else if (hours) {
        this.isExpiring$.next(true);
        if (hours === 1) {
          return hours + " hour";
        } else {
          return hours + " hours";
        }
      } else if (minutes) {
        this.isExpiring$.next(true);
        if (minutes === 1) {
          return minutes + " minute";
        } else {
          return minutes + " minutes";
        }
      } else {
        return "";
      }
    }
  }
  