import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from "@angular/core";
import { NewsfeedService } from "src/app/services/newsfeed.service";
import { KMSObject } from "src/app/shared/entities";
import { LoggerService } from "src/app/services/logger.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "kms-follow-button",
  templateUrl: "./follow-button.component.html",
  styleUrls: ["./follow-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowButtonComponent implements OnInit {
  @Input()
  objectFollowedId: string;

  @Input()
  type: KMSObject;

  @Input()
  color: string;

  @Input()
  size: string;

  @Input()
  flatStyle = false;

  @Input()
  isDeprecated = false;

  @Output()
  followedEvt = new EventEmitter();
  @Output()
  unFollowedEvt = new EventEmitter();

  isFollowed = false;
  constructor(
    private readonly newsfeedService: NewsfeedService,
    private readonly notificationSvc: NotificationService,
    private readonly userSvc: UserService,
    private readonly logger: LoggerService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.userSvc.userProfile$.subscribe(p => {
      if (
        p.followedHashtags.find(h => h.hashtagId === this.objectFollowedId) !=
        null
      ) {
        this.isFollowed = true;
        this.followedEvt.emit(this.objectFollowedId);
      }

      if (
        p.followedPeople.find(u => u.userId === this.objectFollowedId) != null
      ) {
        this.isFollowed = true;
        this.followedEvt.emit(this.objectFollowedId);
      }

      this.cd.markForCheck();
    });
  }

  async Follow() {
    try {
      const { transactionId } = await this.newsfeedService
        .follow(this.objectFollowedId, this.type)
        .toPromise();

      await this.notificationSvc
        .waitForTransactionResult(transactionId)
        .toPromise();

      this.userSvc.followAction.next(null);

      this.isFollowed = true;
      this.cd.markForCheck();

      switch (this.type) {
        case KMSObject.Hashtag:
          this.logger.event("hashtag.follow", {
            targetId: this.objectFollowedId
          });
          break;
        case KMSObject.User:
          this.logger.eventMultiple("user.follow", [
            {
              targetUserId: this.userSvc.userId
            },
            {
              targetUserId: this.objectFollowedId
            }
          ]);
          break;
        default:
          break;
      }
      this.followedEvt.emit(this.objectFollowedId);
    } catch (e) {
      this.notificationSvc.showDetailsError(e);
    }
  }

  async Unfollow() {
    const { transactionId } = await this.newsfeedService
      .unfollow(this.objectFollowedId, this.type)
      .toPromise();

    await this.notificationSvc
      .waitForTransactionResult(transactionId)
      .toPromise();

    this.userSvc.followAction.next(null);

    this.isFollowed = false;
    this.cd.markForCheck();

    switch (this.type) {
      case KMSObject.Hashtag:
        /*this.logger.event("hashtag.unfollow", {
          targetId: this.objectFollowedId
        });*/
        this.userSvc.trackEvent("hashtag.unfollow",null,this.objectFollowedId).subscribe(() => {});;
        break;
      case KMSObject.User:

        /*this.logger.eventMultiple("user.unfollow", [
          {
            targetUserId: this.userSvc.userId
          },
          {
            targetUserId: this.objectFollowedId
          }
        ]);*/

        this.userSvc.trackEvent("user.unfollow",null,this.objectFollowedId).subscribe(() => {});
        break;
      default:
        break;
    }
    this.unFollowedEvt.emit(this.objectFollowedId);
  }

  get isMyProfile() {
    if (this.userSvc.userId) {
      return this.objectFollowedId === this.userSvc.userId;
    } else {
      this.userSvc.GetMyProfile();
      return this.objectFollowedId === this.userSvc.userId;
    }
  }
}
