import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, catchError, filter } from "rxjs/operators";

import { UploadService } from "./upload.service";
import { SearchService, KMSSearchResults } from "./search.service";
import {
  NetworkInfo,
  Network,
  UserInfo,
  NavLink,
  HashtagInfo,
  ImageEntityTypes,
  ImageType,
  MapDoc,
  Attachment
} from "../shared/entities";
import { ConfigurationService } from "./configuration.service";

@Injectable({
  providedIn: "root"
})
export class ExternalNetworkService {
  // networkId = "";

  private defaultAvatar$: Observable<string>;
  private defaultNavlinkAvatar$: Observable<string>;
  private defaultBackground$: Observable<string>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly uploaderSvc: UploadService,
    private readonly searchSvc: SearchService,
    private readonly configSvc: ConfigurationService
  ) {
    this.configSvc.assetsConfig.pipe(filter(r => r != null)).subscribe(() => {
      this.defaultAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.ExternalNetwork,
        ImageType.Avatar,
        `external/api/network/avatar/default/get`
      );
      this.defaultNavlinkAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.NavLink,
        ImageType.Generic,
        `external/api/network/navlink/default/get`
      );
      this.defaultBackground$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Network,
        ImageType.Cover,
        `external/api/network/background/default/get`
      );
    });
  }

  public getExternalNetwork(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>(`external/api/network`);
  }

  public getExternalNetworkWithFilter(query: string) : Observable<NetworkInfo[]> {
    return this.httpClient.post<NetworkInfo[]>(
      `external/api/network/getWithQuery`,
      {
        query
      });
  }

  public getNetworkById(networkId: string): Observable<Network> {
    return this.httpClient.get<Network>(`external/api/network/` + networkId);
  }

  public getNetworkByHashtagId(hashtagId: string) {
    return this.httpClient.get<Network>(
      `external/api/network/NetworkBy/${hashtagId}`
      // `external/api/network/NetworkHashtag/${hashtagId}`
    );
  }

  public getPeopleByNetworkId(networkId: string) {
    return this.httpClient.get<UserInfo[]>(
      `external/api/network/people/${networkId}`
    );
  }

  public saveAbstract(abstract: string, networkId: string) {
    return this.httpClient.post(
      `external/api/network/${networkId}/abstract`,
      JSON.stringify(abstract)
    );
  }

  public getDefaultNetworkAvatar(): Observable<string> {
    return this.defaultAvatar$;
  }

  public uploadNetworkAvatar(
    file: File,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `external/api/network/${networkId}/avatar/upload`,
      file
    );
  }

  public getNetworkThumbnail(imageUrl: string): Observable<string> {
    return this.httpClient.get<string>(
      `external/api/network/avatar/get/thumbnail/image?path=${encodeURIComponent(
        imageUrl
      )}`
    );
  }

  public getNetworkDefaultBackground(): Observable<string> {
    return this.defaultBackground$;
  }

  public uploadNetworkBackground(
    file: File,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `external/api/network/${networkId}/background/upload`,
      file
    );
  }

  public getNavLinkImage(imageUrl: string): Observable<string> {
    return this.httpClient.get<string>(
      `external/api/network/navlink/get/icon?path=${encodeURIComponent(
        imageUrl
      )}`
    );
  }

  public getDefaultNavLinkImage(): Observable<string> {
    return this.defaultNavlinkAvatar$;
  }

  public uploadNavlinkImage(
    file: File,
    navlinkId: string,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `external/api/network/${networkId}/navlink/${navlinkId}/upload`,
      file
    );
  }

  public uploadNavlinkAttachment(
    file: File,
    navlinkId: string,
    networkId: string
  ): Observable<Attachment[]> {
    return this.uploaderSvc.uploadFile(
      `external/api/network/${networkId}/navlink/${navlinkId}/attachments/upload`,
      file
    );
  }

  public deleteNavlinkAttachment(
    navlinkId: string,
    networkId: string,
    attachmentId: string
  ): Observable<any> {
    return this.httpClient.delete(
      `external/api/network/${networkId}/navlink/${navlinkId}/attachments/${attachmentId}`
    );
  }

  public saveNavLinks(
    networkId: string,
    title: string,
    stickyPosts: NavLink[]
  ): Observable<any> {
    return this.httpClient.post(`external/api/network/${networkId}/navlinks`, {
      item1: title,
      item2: stickyPosts
    });
  }

  public saveNetworkCoreTeam(
    users: UserInfo[],
    networkId: string
  ): Observable<any> {
    return this.httpClient.post(
      `external/api/network/${networkId}/koUsers/save`,
      users
    );
  }

  public downloadAttachment(url: string, networkId: string) {
    return this.httpClient.post(
      `external/api/network/${networkId}/attachments`,
      "'" + url + "'",
      {
        responseType: "blob"
      }
    );
  }

  public downloadAttachmentGetUrl(url: string, networkId: string) {
    return `external/api/network/${networkId}/attachments/${btoa(url)}`;
  }

  public searchHashtag(
    query: string,
    networkId: string
  ): Observable<HashtagInfo[]> {
    const params = {
      filter: `isPrivate eq true and search.ismatch('${networkId}','promotingNetworksIds')`
    };
    return this.searchSvc.externalQuery("Hashtags", query, params).pipe(
      catchError(() => of([])),
      map((searchRes: KMSSearchResults<HashtagInfo>) =>
        searchRes.results
          .map(result => result.document)
          .map(val => MapDoc.docToHashInfo(val))
      )
    );
  }
}
