import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit
} from "@angular/core";
import { NotificationService } from "./services/notification.service";
import { UserService } from "./services/user.service";
import { ConfigurationService } from "./services/configuration.service";
import { WelcomeExperienceService } from "./services/first-access.service";
import { Router, NavigationEnd } from "@angular/router";
import {
  Disclaimer,
  User,
  UserCategory,
  WelcomeExperienceStatus
} from "./shared/entities";
import { NgxPermissionsService } from "ngx-permissions";
import { filter } from "rxjs/operators";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { LoggerService } from "./services/logger.service";
import { ConfigService } from "@ngx-config/core";
// import { NotificationService } from "./services/notification.service";
@Component({
  selector: "kms-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
  urlRedirect: string;
  isNavigationEvent = false;
  innerWidth: any;

  constructor(
    private readonly notificationSvc: NotificationService,
    public readonly userSvc: UserService,
    public readonly configSvc: ConfigurationService,
    public welcomeExperienceSvc: WelcomeExperienceService,
    private readonly permissionSvc: NgxPermissionsService,
    private router: Router,
    private adalSvc: MsAdalAngular6Service,
    private readonly logger: LoggerService,
    private readonly config: ConfigService
  ) {}

  ngOnInit() {
    const settings = this.config.getSettings();
    const { killSwitches } = settings;

    if (killSwitches.global) {
      this.router.navigate(["/deny-access"], {
        queryParams: { disableAll: true }
      });
      // set assetsConfig for blob configuration
      // this.configSvc.getAssetsServiceConfig();
      this.configSvc.getPortalServiceConfig();

      this.calculateWindowSize();
      const _this = this;
      window.onresize = function(event) {
        _this.calculateWindowSize();
      };

      if (!this.adalSvc.isAuthenticated) {
        localStorage.setItem("kms.login.redirectUri", document.location.href);
      } else {
        this.handlePostLoginRedirect();
      }

      this.welcomeExperienceSvc.isFirstAccessActivated.next(true);
      this.welcomeExperienceSvc.isLoaded.next(true);
    } else {
      this.notificationSvc.connect();

      this.handleApplicationStart();
      this.calculateWindowSize();
      const _this = this;
      window.onresize = function(event) {
        _this.calculateWindowSize();
      };

      this.handleResetPageScrollOnNavigate();

      if (!this.adalSvc.isAuthenticated) {
        localStorage.setItem("kms.login.redirectUri", document.location.href);
      } else {
        this.handlePostLoginRedirect();
      }
    }
  }

  ngAfterViewInit() {
    // Refresh app component. Used when the app gives a 403 status, to force status reload.
    // If is valid, continue to navigate. Otherwise, show deny-access page
    this.configSvc.refreshApp.subscribe((refresh: boolean) => {
      if (refresh) {
        this.ngOnInit();
      }
    });
  }

  private async handleApplicationStart() {
    const check: [
      WelcomeExperienceStatus,
      User
    ] = await this.welcomeExperienceSvc.checkWelcomeExperience().toPromise();

    // set assetsConfig for blob configuration
    // this.configSvc.getAssetsServiceConfig();
    this.configSvc.getPortalServiceConfig();

    // check for user experience
    const status = check["item1"];
    const profile = check["item2"];
    this.welcomeExperienceSvc.welcomeExperienceStatus.next(status);
    switch (status) {
      case WelcomeExperienceStatus.OK: {
        this.welcomeExperienceSvc.isFirstAccessActivated.next(false);

        if (profile) {
          const userProfile = new User(profile);
          this.permissionSvc.loadPermissions(
            userProfile.mapRoleToString()
          );
          // Set Current User
          this.logger.setCurrentUser(userProfile.upn, userProfile.userId);

          ////////////////////////////
          //// FOR DEBUG PURPOSES ////
          //userProfile.category = <UserCategory>UserCategory.Guest;
          ////////////////////////////
          ////////////////////////////
          
          switch (userProfile.category) {
            case UserCategory.Guest: {
              if (
                // if the user has logged in, allow navigation on pages other then deny-access and home page,
                // otherwise, he will be redirected to external network list
                !(
                  localStorage.getItem("guest-is-already-access") &&
                  window.location.pathname !== "/deny-access" &&
                  window.location.pathname !== "/"
                )
              ) {
                localStorage.setItem("guest-is-already-access", "true");
                this.permissionSvc.loadPermissions(
                  userProfile.mapRoleToString()
                );

                this.userSvc.setUserProfile(userProfile);
                await this.router.navigate(["/external-network/list"]);
              } else {
                this.permissionSvc.loadPermissions(
                  userProfile.mapRoleToString()
                );

                this.userSvc.setUserProfile(userProfile);
              }

              this.welcomeExperienceSvc.isLoaded.next(true);
              break;
            }
            case UserCategory.Employee:
            case UserCategory.External: {
              this.userSvc.setUserProfile(userProfile);

              // workaround for redirect url when in deny access
              if (window.location.pathname === "/deny-access") {
                await this.router.navigate(["/"]);
              }

              this.welcomeExperienceSvc.isLoaded.next(true);
              break;
            }
          }
        }
        break;
      }
      case WelcomeExperienceStatus.AcceptDisclaimer: {
        if (profile) {
          this.permissionSvc.loadPermissions(
            new User(profile).mapRoleToString()
          );
        }

        this.welcomeExperienceSvc.isFirstAccessActivated.next(false);
        await this.router.navigate(["/first-access/step1"]);

        this.welcomeExperienceSvc.isLoaded.next(true);
        break;
      }
      case WelcomeExperienceStatus.CompleteWelcomeExperience: {
        if (profile) {
          this.permissionSvc.loadPermissions(
            new User(profile).mapRoleToString()
          );
        }

        this.welcomeExperienceSvc.isFirstAccessActivated.next(false);
        await this.router.navigate(["/first-access/step1"]);

        this.welcomeExperienceSvc.isLoaded.next(true);
        break;
      }
      default: {
        this.welcomeExperienceSvc.isFirstAccessActivated.next(true);
        await this.router.navigate(["/deny-access"]);

        this.welcomeExperienceSvc.isLoaded.next(true);
        break;
      }
    }
  }

  private handleResetPageScrollOnNavigate() {
    this.router.events
      .pipe(filter(ev => ev instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
  }

  private handlePostLoginRedirect() {
    const postLoginRedirect = localStorage.getItem("kms.login.redirectUri");
    // Redirect to the page in local storage, unless it is the deny-access page
    // (if the user cannot navigate, it will receive another 403 and will go on deny-access page)
    if (postLoginRedirect && postLoginRedirect !== "/deny-access") {
      // postLoginRedirect.replace(document.location.origin, "")
      this.router.navigateByUrl(
        postLoginRedirect.replace(document.location.origin, "")
      );
      localStorage.setItem("kms.login.redirectUri", "");
    }
  }

  isUserDisclaimerValid(
    disclaimerAcceptedDate: Date,
    currentDisclaimer: Disclaimer
  ): boolean {
    if (
      currentDisclaimer &&
      new Date(disclaimerAcceptedDate) <=
        new Date(currentDisclaimer.activeSince)
    ) {
      return false;
    } else {
      return true;
    }
  }

  calculateWindowSize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 990) {
      this.configSvc.isMobile.next(true);
    } else {
      this.configSvc.isMobile.next(false);
    }
    if (this.innerWidth <= 1208) {
      this.configSvc.isMobileOrSmallDesktop.next(true);
    }  else {
      this.configSvc.isMobileOrSmallDesktop.next(false);
    }
    if (this.innerWidth === 768) {
      this.configSvc.isIpad.next(true);
    } else {
      this.configSvc.isIpad.next(false);
    }
  }
}
