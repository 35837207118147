import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { KMSObject } from 'src/app/shared/entities';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'kms-success-story-tab',
  templateUrl: './success-story-tab.component.html',
  styleUrls: ['./success-story-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SuccessStoryTabComponent implements OnInit {

  @Input() successStories;

  KMSObject = KMSObject;

  constructor() { }

  ngOnInit() {
  }

  getIcon(status: string) {
    switch (status) {
      case "Draft":
      return "fas fa-pencil-ruler";
      case "Proposed":
      return "fas fa-level-up-alt";
      case "Published":
      return "fas fa-book";
      case "Rejected":
      return "fa fa-ban";
    }
  }

}
