import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ignoreHtmlTagsAndSlice"
})
export class IgnoreHtmlTagsPipe implements PipeTransform {
  transform(value: string, start?: number, end?: number): any {
    if (value) {
      value = value.replace(/<[^>]*>/g, "");
      const length = value.length;
      if (start != null && end != null) {
        value = value.slice(start, end);
      }
      return value + (length > end ? "..." : "");
    }
    return value;
  }
}
