import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NetworkService } from "src/app/services/network.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "kms-upload-image",
  templateUrl: "./upload-image.component.html",
  styleUrls: ["./upload-image.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UploadImageComponent implements OnInit {
  canEditImage = false;

  @Input()
  autoOpen = false;

  @Input()
  edit = false;

  @Input()
  autoUpload = false;

  @Input()
  placeholder = "Browse...";

  @Input()
  disableCancelIfHasFile = false;

  @Output()
  uploadFile: EventEmitter<File> = new EventEmitter();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  isEditing: EventEmitter<any> = new EventEmitter();

  fileToUpload: File = null;

  @ViewChild("uploader")
  uploader: ElementRef;

  get fileName() {
    return this.fileToUpload ? this.fileToUpload.name : "";
  }

  constructor(
    private cd: ChangeDetectorRef,
    private networkSvc: NetworkService,
    private activatedRoute: ActivatedRoute,
    private userSvc: UserService
  ) {}

  ngOnInit() {
    const roles = this.userSvc.userProfile.roles;
    const networkId = this.activatedRoute.snapshot.paramMap.get("networkId");

    if (
      (roles && (roles.admin === true || roles.kmTeam === true)) ||
      (roles &&
        roles.copFacilitatorScopes &&
        roles.copFacilitatorScopes.includes(networkId))
    ) {
      this.canEditImage = true;
    } else {
      this.canEditImage = false;
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.autoUpload) {
      this.upload();
    }
  }

  editMode() {
    this.edit = true;

    if (this.autoOpen) {
      this.uploader.nativeElement.click();
    }

    this.isEditing.emit(null);

    this.cd.markForCheck();
  }

  upload() {
    if (this.fileToUpload) {
      this.uploadFile.emit(this.fileToUpload);
      this.fileToUpload = null;
    }
    this.edit = false;
  }

  cancelEdit() {
    this.fileToUpload = null;
    this.cancel.emit(null);
    if (!this.disableCancelIfHasFile || this.fileToUpload) {
      this.edit = false;
    }
  }
}
