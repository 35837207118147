import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation
} from "@angular/core";
import * as dayjs from "dayjs";
import { KMSObject } from "src/app/shared/entities";
import { BehaviorSubject } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: "kms-open-challenge-item",
  templateUrl: "./open-challenge-item.component.html",
  styleUrls: ["./open-challenge-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OpenChallengeItemComponent implements OnInit {
  @Input() element;
  @Input() isExpired: boolean;

  isExpiring$ = new BehaviorSubject(false);
  isMobile: boolean;
  KMSObject = KMSObject;

  constructor(private readonly configSvc: ConfigurationService) {}

  ngOnInit() {
    this.configSvc.isMobile.subscribe(el => {
      this.isMobile = el;
    });
  }

  get timeLeft() {
    const today = dayjs();
    const endDate = dayjs(this.element.challengeEndDate);

    const days = endDate.diff(today, "day", false);
    const hours = endDate.diff(today, "hour", false);
    const minutes = endDate.diff(today, "minute", false);

    if (days) {
      this.isExpiring$.next(false);
      if (days === 1) {
        return days + " day";
      } else {
        return days + " days";
      }
    } else if (hours) {
      this.isExpiring$.next(true);
      if (hours === 1) {
        return hours + " hour";
      } else {
        return hours + " hours";
      }
    } else if (minutes) {
      this.isExpiring$.next(true);
      if (minutes === 1) {
        return minutes + " minute";
      } else {
        return minutes + " minutes";
      }
    } else {
      return "";
    }
  }
}
