import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";

import { Observable } from "rxjs";
import { SuccessStory } from "src/app/shared/entities";
import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { map } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: "kms-success-story",
  templateUrl: "./success-story.component.html",
  styleUrls: ["./success-story.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SuccessStoryComponent implements OnInit, AfterViewInit {
  constructor(
    private el: ElementRef<HTMLElement>,
    private searchSvc: SearchService,
    private userSvc: UserService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    private readonly configSvc: ConfigurationService
  ) {}

  publishedSuccessStory: Observable<SuccessStory[]>;
  mySuccessStory: Observable<SuccessStory[]>;
  currentTab = "Archive";

  filter = {
    filter: "type eq 'SuccessStory' and status eq 'Published'",
    orderBy: ["modified desc"],
    select: ["id", "name", "involvedPeople", "involvedPeopleIds", "status", "logoUrl"],
    top: 3
  };

  myFilter = {
    filter: `type eq 'SuccessStory' and search.ismatch('${
      this.userSvc.userId
    }', 'involvedPeopleIds')`,
    orderBy: ["modified desc"],
    select: ["id", "name", "involvedPeople", "involvedPeopleIds", "status", "logoUrl"],
    top: 3
  };
  isMobile = false;
  ngOnInit() {
    this.configSvc.isMobile.subscribe(el => {
      this.isMobile = el;
    });
    window.requestIdleCallback(() => {
      this.getPublishedStories();
      this.cd.markForCheck();
    }, { timeout: 500 });
  }

  ngAfterViewInit(): void {
    this.el.nativeElement
      .querySelector(".nav")
      .classList.add(
        "nav-tabs-transparent",
        "indicator-primary",
        "nav-tabs-full",
        "nav-tabs-2"
      );
  }

  private getPublishedStories() {
    this.publishedSuccessStory = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.filter)
      .pipe(
        map((successStories: KMSSearchResults<SuccessStory>) =>
          successStories.results.map(r => r.document)
        )
      );
  }

  private getMyStories() {
    this.mySuccessStory = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.myFilter)
      .pipe(
        map((successStories: KMSSearchResults<SuccessStory>) =>
          successStories.results.map(r => r.document)
        )
      );
  }

  onGoToArchiveClick() {
    this.router.navigateByUrl("success-story/archive");
  }

  onGoToMyStoriesClick() {
    this.router.navigateByUrl("success-story/manage");
  }

  tabClick(curTab: string) {
    this.currentTab = curTab;
    switch (curTab) {
      case "Archive":
        if (this.publishedSuccessStory == null) {
          this.getPublishedStories();
        }
        break;
      case "MyStories":
        if (this.mySuccessStory == null) {
          this.getMyStories();
        }
        break;
    }
  }
}
