import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { DragulaModule } from "ng2-dragula";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorNotificationComponent } from "./error-notification/error-notification.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { UploadImageComponent } from "./upload-image/upload-image.component";
import { ButtonOverridesDirective } from "src/app/shared/directives/button-overrides.directive";
import { DefocusButtonDirective } from "src/app/shared/directives/defocus-button.directive";
import { SuppresNavigationDirective } from "src/app/shared/directives/suppres-navigation.directive";
import { CollapseComponent } from "./collapse/collapse.component";
import { CounterComponent } from "./counter/counter.component";
import { FootbarComponent } from "./footbar/footbar.component";
import { FooterComponent } from "./footer/footer.component";
import { GotopComponent } from "./gotop/gotop.component";
import { KmsMenuComponent } from "./kms-menu/kms-menu.component";
import { CardModule } from "../card/card.module";
import { AvatarViewModule } from "../avatar-view/avatar-view.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FollowButtonModule } from "../follow-button/follow-button.module";
import { MiniUserModule } from "../mini-user/mini-user.module";
import { PipesModule } from "../pipes/pipes.module";
import { AlertModule } from "ngx-bootstrap/alert";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ClickWaitModule } from "../click-wait/click-wait.module";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ContactsListComponent } from "./kms-menu/contacts-list/contacts-list.component";
import { WebinarsListComponent } from "./kms-menu/webinars-list/webinars-list.component";
import { ChallengesListComponent } from "./kms-menu/challenges-list/challenges-list.component";
import { OpenChallengeItemComponent } from "./kms-menu/challenges-list/open-challenge-item/open-challenge-item.component";
import {MatButtonModule} from '@angular/material/button';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from "./kms-menu/bottom-sheet/bottom-sheet.component";
import {MatListModule} from '@angular/material/list'

// PLEASE AVOID ANY ADDITION HERE!!
const DECLARATION = [
  ErrorNotificationComponent,
  NotificationsComponent,
  SearchBarComponent,
  SpinnerComponent,
  UploadImageComponent,
  ButtonOverridesDirective,
  DefocusButtonDirective,
  SuppresNavigationDirective,
  CollapseComponent,
  CounterComponent,
  FootbarComponent,
  FooterComponent,
  GotopComponent,
  KmsMenuComponent,
  ContactsListComponent,
  WebinarsListComponent,
  ChallengesListComponent,
  OpenChallengeItemComponent,
  BottomSheetComponent
];

// PLEASE AVOID ANY ADDITION HERE!!
const IMPORTS = [
  CommonModule,
  RouterModule,
  AlertModule,
  DragulaModule,
  FormsModule,
  CollapseModule,
  CardModule,
  TooltipModule,
  ModalModule,
  AvatarViewModule,
  FollowButtonModule,
  MiniUserModule,
  PipesModule,
  ClickWaitModule,
  ReactiveFormsModule,
  MatButtonModule,
  MatBottomSheetModule,
  MatListModule
];
@NgModule({
  imports: [...IMPORTS, TypeaheadModule.forRoot(), PopoverModule.forRoot(), BsDropdownModule.forRoot()],
  declarations: [...DECLARATION],
  exports: [...IMPORTS, ...DECLARATION]
})
export class CoreModule {}
