import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationGuard } from "microsoft-adal-angular6";
import { RedirectGuard, GuestGuard, LeleGuard } from "./shared/guards";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";

const routes: Routes = [
  {
    path: "",
    loadChildren: "./pages/home-page/home-page.module#HomePageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "profile",
    loadChildren: "./pages/profile-page/profile-page.module#ProfilePageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "first-access",
    loadChildren:
      "./pages/first-access-page/first-access-page.module#FirstAccessPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: true },
  },
  {
    path: "how-to",
    loadChildren: "./pages/faqs-page/faqs-page.module#FaqsPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "lesson-learned",
    loadChildren:
      "src/app/pages/lesson-learned-page/lesson-learned-page.module#LessonLearnedPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard, LeleGuard],
    data: { isPrivate: false },
  },
  {
    path: "hashtag",
    loadChildren: "./pages/hashtag-page/hashtag-page.module#HashtagPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "network",
    loadChildren: "src/app/pages/network/network.module#NetworkModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "followed",
    loadChildren:
      "src/app/pages/followed-page/followed-page.module#FollowedPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "favorites",
    loadChildren:
      "src/app/pages/favorite-conversations/favorite-conversations.module#FavoriteConversationsModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "rankings",
    loadChildren:
      "src/app/pages/ranking-page/ranking-page.module#RankingPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "hashtagList",
    loadChildren:
      "src/app/pages/hashtag-list-page/hashtag-list-page.module#HashtagListPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "search",
    loadChildren:
      "src/app/pages/search-page/search-page.module#SearchPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "deny-access",
    loadChildren:
      "src/app/pages/deny-access/deny-access.module#DenyAccessModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "ik",
    loadChildren: "src/app/pages/ik-page/ik-page.module#IkPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "ik-archive",
    loadChildren:
      "src/app/pages/ik-archive-page/ik-archive-page.module#IkArchivePageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "specialtag",
    loadChildren:
      "src/app/pages/specialtag-page/specialtag-page.module#SpecialtagPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "conversation",
    loadChildren:
      "src/app/pages/conversation-page/conversation-page.module#ConversationPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "relatedIk/:objId",
    loadChildren:
      "src/app/pages/related-ik-page/related-ik-page.module#RelatedIkPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "idea",
    loadChildren: "src/app/pages/idea-page/idea-page.module#IdeaPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "success-story",
    loadChildren:
      "src/app/pages/success-story/success-story.module#SuccessStoryModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "tech-pub",
    loadChildren: "src/app/pages/tech-pub/tech-pub.module#TechPubModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "challenge",
    loadChildren:
      "src/app/pages/challenge-page/challenge-page.module#ChallengePageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "knowledge-nugget",
    loadChildren:
      "src/app/pages/knowledge-nugget-page/knowledge-nugget-page.module#KnowledgeNuggetPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard, GuestGuard],
    data: { isPrivate: false },
  },
  {
    path: "notifications",
    loadChildren:
      "src/app/pages/notifications-page/notifications-page.module#NotificationsPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: true },
  },
  {
    path: "mobile-widgets",
    loadChildren:
      "src/app/pages/widget-mobile-page/widget-mobile-page.module#WidgetMobilePageModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "webinars",
    loadChildren:
      "src/app/pages/webinars-page/webinars-page.module#WebinarsPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "external-network",
    loadChildren:
      "src/app/pages/external-network/external-network.module#ExternalNetworkModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: true },
  },
  {
    path: "guest-profile",
    loadChildren:
      "src/app/pages/guest-profile/guest-profile.module#GuestProfileModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: true },
  },
  {
    path: "users-list",
    loadChildren: "src/app/pages/users-list/users-list.module#UsersListModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: true },
  },
  {
    path: "dotlessonslearned",
    loadChildren:
      "src/app/pages/dot-lessons-learned/dot-lessons-learned.module#DOTLessonsLearnedModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "unauthorized",
    loadChildren:
      "src/app/pages/unauthorized-page/unauthorized/unauthorized.module#UnauthorizedModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
  {
    path: "**",
    loadChildren:
      "src/app/pages/not-found-page/not-found-page.module#NotFoundPageModule",
    canActivate: [RedirectGuard, AuthenticationGuard],
    data: { isPrivate: false },
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      onSameUrlNavigation: "reload",
      preloadingStrategy: QuicklinkStrategy,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
