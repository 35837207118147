import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";
import * as dayjs from "dayjs";
import { Observable, of } from "rxjs";
import { Challenge, ImpactingKnowledgeType } from "src/app/shared/entities";
import { map, catchError, tap } from "rxjs/operators";
import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Utilities } from "src/app/shared/utils";

@Component({
  selector: "kms-open-challenge",
  templateUrl: "./open-challenge.component.html",
  styleUrls: ["./open-challenge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OpenChallengeComponent implements OnInit, AfterViewInit {
  expiringSoon: Observable<Challenge[]>;
  expiringSoonError = false;
  expired: Observable<Challenge[]>;
  expiredError = false;
  mostVoted: Observable<Challenge[]>;
  mostVotedError = false;
  loadingIndicator = false;

  noDataFoundErrorMessage = "There are no Challenges so far";
  serverErrorMessage = "An error occurred!";

  filter = {
    select: ["id", "name", "logoUrl", "ideasCount", "challengeEndDate"],
    filter: `(typeId eq ${ImpactingKnowledgeType.Challenge})`,
    orderBy: ["ideasCount desc"],
    top: 5
  };

  expiringSoonFilter = {
    select: ["id", "name", "logoUrl", "ideasCount", "challengeEndDate"],
    filter: `(typeId eq ${
      ImpactingKnowledgeType.Challenge
    } and status eq 'Open')`,
    orderBy: ["challengeEndDate asc"]
  };

  expiredFilter = {
    select: ["id", "name", "logoUrl", "ideasCount", "challengeEndDate"],
    filter: `(typeId eq ${
      ImpactingKnowledgeType.Challenge
    } and status eq 'Closed')`,
    orderBy: ["challengeEndDate desc"],
    top: 5
  };

  currentTab = "Open";
  isMobile = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    private searchSvc: SearchService,
    private readonly cd: ChangeDetectorRef,
    private readonly configSvc: ConfigurationService
  ) {}

  ngOnInit() {
    this.updateSearchFilters();
    this.configSvc.isMobile.subscribe(el => {
      this.isMobile = el;
    });
    window.requestIdleCallback(() => {
      this.getExpiringSoon();
      this.cd.markForCheck();
    }, { timeout: 500 });
  }

  ngAfterViewInit(): void {
    this.el.nativeElement
      .querySelector(".nav")
      .classList.add(
        "nav-tabs-transparent",
        "indicator-primary",
        "nav-tabs-full",
        "nav-tabs-3"
      );
  }

  private updateSearchFilters() {
    const todayDate = dayjs().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    this.expiredFilter.filter += ` and challengeEndDate le ${todayDate}`;
    this.expiringSoonFilter.filter += ` and challengeStartDate le ${todayDate} and challengeEndDate ge ${todayDate}`;
    this.filter.filter += ` and challengeStartDate le ${todayDate}`;
  }

  private getExpiringSoon() {
    this.loadingIndicator = true;
    this.expiringSoon = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.expiringSoonFilter)
      .pipe(
        map((challenges: KMSSearchResults<any>) =>
          challenges.results.map(r => r.document)
        ),
        map(challenges => challenges.map(challenge => {
          challenge.challengeEndDate = Utilities.adjustDateMilanToUtc(challenge.challengeEndDate);
          return challenge;
        })),
        tap(() => this.loadingIndicator = false),
        catchError(() => {
          this.loadingIndicator = false;
          this.expiringSoonError = true;
          return of([]);
        })
      );
  }

  private getExpired() {
    this.loadingIndicator = true;
    this.expired = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.expiredFilter)
      .pipe(
        map((challenges: KMSSearchResults<any>) =>
          challenges.results.map(r => r.document)
        ),
        map(challenges => challenges.map(challenge => {
          challenge.challengeEndDate = Utilities.adjustDateMilanToUtc(challenge.challengeEndDate);
          return challenge;
        })),
        tap(() => (this.loadingIndicator = false)),
        catchError(() => {
          this.loadingIndicator = false;
          this.expiredError = false;
          return of([]);
        })
      );
  }

  private getMostVoted() {
    this.loadingIndicator = true;
    this.mostVoted = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.filter)
      .pipe(
        map((challenges: KMSSearchResults<any>) =>
          challenges.results.map(r => r.document)
        ),
        map(challenges => challenges.map(challenge => {
          challenge.challengeEndDate = Utilities.adjustDateMilanToUtc(challenge.challengeEndDate);
          return challenge;
        })),
        tap(() => this.loadingIndicator = false),
        catchError(() => {
          this.mostVotedError = true;
          return of([]);
        })
      );
  }

  isBeforeToday(date) {
    const today = dayjs();
    const ref = dayjs(date);

    return ref.isBefore(today, "millisecond");
  }

  tabClick(curTab: string) {
    this.currentTab = curTab;
    switch (curTab) {
      case "Open":
        if (this.expiringSoon == null) {
          this.getExpiringSoon();
          this.cd.markForCheck();
        }
        break;
      case "Closed":
        if (this.expired == null) {
          this.getExpired();
          this.cd.markForCheck();
        }
        break;
      case "Popular":
        if (this.mostVoted == null) {
          this.getMostVoted();
          this.cd.markForCheck();
        }
        break;
    }
  }
}
