import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

@Pipe({
  name: "amFromNow"
})
export class AmFromNowPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return (dayjs(value) as any).fromNow(true);
  }
}
