import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { UserService } from "../services/user.service";
import { map, tap } from "rxjs/operators";
import { Disclaimer, ExternalUser, UserCategory, UserInfo } from "./entities";
import { ConfigurationService } from "../services/configuration.service";
import { WelcomeExperienceService } from "../services/first-access.service";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { WebinarsService } from "../services/webinars.service";
import { Observable } from "rxjs";

@Injectable()
export class DisclaimerGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private configService: ConfigurationService,
    private firstAccessService: WelcomeExperienceService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //  return this.userService.isCurrentUserRegistered().subscribe(isRegistered => {
    //     if (isRegistered) {
    //       return true;
    //     } else {
    //       this.router.navigate(["/firs-access"], {
    //         queryParams: {
    //           return: state.url
    //         }
    //       });
    //       return false;
    //     }
    //   });

    // const user = await this.userService.getCurrentUserInfo().toPromise();
    // const disclaimer = await this.firstAccessService.getValidDisclaimer().toPromise();

    return this.userService.userProfile$.pipe(
      map((u) => {
        if (u === null) {
          this.firstAccessService.isFirstAccessActivated.next(true);
          this.router.navigate(["/first-access/step1"], {
            queryParams: {
              return: state.url,
            },
          });
          return false;
        } else {
          // if (this.checkDisclaimer(u.disclaimerAcceptedDate, d)) {
          //   this.firstAccessService.isFirstAccessActivated.next(true);
          //   this.router.navigate(["/first-access/step2"], {
          //     queryParams: {
          //       return: state.url
          //     }
          //   });
          //   return false;
          // } else {
          //   this.firstAccessService.isFirstAccessActivated.next(false);
          //   return true;
          // }
        }
      })
    );
    // return forkJoin([
    //   this.userService.userProfile$,
    //   this.configService.currentDisclaimer$
    // ]).pipe(
    //   map(([u, d]) => {
    //     if (u === null) {
    //       this.firstAccessService.isFirstAccessActivated.next(true);
    //       this.router.navigate(["/first-access/step1"], {
    //         queryParams: {
    //           return: state.url
    //         }
    //       });
    //       return false;
    //     } else {
    //       if (this.checkDisclaimer(u.disclaimerAcceptedDate, d)) {
    //         this.firstAccessService.isFirstAccessActivated.next(true);
    //         this.router.navigate(["/first-access/step2"], {
    //           queryParams: {
    //             return: state.url
    //           }
    //         });
    //         return false;
    //       } else {
    //         this.firstAccessService.isFirstAccessActivated.next(false);
    //         return true;
    //       }
    //     }
    //   })
    // );

    // if (user === null) {
    //   this.router.navigate(["/firs-access/0"], {
    //     queryParams: {
    //       return: state.url
    //     }
    //   });
    //   return false;
    // } else {
    //   if (this.checkDisclaimer(user.disclaimer, disclaimer)) {
    //     this.router.navigate(["/firs-access/1"], {
    //       queryParams: {
    //         return: state.url
    //       }
    //     });
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
  }

  checkDisclaimer(
    disclaimerAcceptedDate: Date,
    currentDisclaimer: Disclaimer
  ): boolean {
    if (disclaimerAcceptedDate < currentDisclaimer.activeSince) {
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private adalSvc: MsAdalAngular6Service
  ) {}

  // use this guard to save reply url when redirect for 401 authorization error
  // and set the isPrivate BehaviourSubject in the usersvc
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isPrivate = route.data["isPrivate"] as boolean;

    this.userSvc.isPrivateSection.next(isPrivate);

    if (!this.adalSvc.isAuthenticated) {
      localStorage.setItem("kms.login.redirectUri", state.url);
    }
    return true;
  }
}

@Injectable()
export class ExternalUserGuard implements CanActivate {
  constructor(private userSvc: UserService) {}

  // use this guard to save reply url when redirect for 401 authorization error
  // and set the isPrivate BehaviourSubject in the usersvc
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.userSvc.userProfile.isExternalConsultant == true &&
      this.userSvc.isGuest == false
    )
      return false;
    else return true;
  }
}

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(private userSvc: UserService) {}

  // use this guard deny access to guest user
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.userSvc.userCategory === UserCategory.Guest) {
      return false;
    }
    return true;
  }
}

@Injectable()
export class LeleGuard implements CanActivate {
  constructor(private userSvc: UserService) {}
  // use this guard deny access to non lele enabled users
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let userProfile;
    console.log("IS LELE ENABLED?");

    if (!this.userSvc.userProfile) {
      userProfile = await this.userSvc
        .GetUserProfile(this.userSvc.userId)
        .toPromise();

      if (
        userProfile.isExternalConsultant == true &&
        !this.userSvc?.externalUserProfile?.isLeleEnabled
      ) {
        return false;
      }
      return true;
    } else {
      if (
        this.userSvc.userProfile.isExternalConsultant == true &&
        !this.userSvc?.externalUserProfile?.isLeleEnabled
      ) {
        return false;
      }
      return true;
    }
  }
}

@Injectable()
export class WebinarGuestGuard implements CanActivate {
  constructor(
    private userSvc: UserService,
    private webinarSvc: WebinarsService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    var userPrivateNetworksIds: String[] = [];
    var webinarPromotingNetworksIds: String[] = [];
    var webinarInvitedPrivateNetworksIds: String[] = [];
    //console.log("webinar id: " + route.url[2].toString());
    var webinarInfo = await this.webinarSvc.getVideoByid(
      route.url[2].toString()
    );
    //console.log("webinar info: " + JSON.stringify(webinarInfo));
    var userPrivateNetworks = await this.userSvc
      .getExternalUserBadge()
      .toPromise();
    //console.log("userPrivateNetworks: " +  JSON.stringify(userPrivateNetworks));
    var userEmail = this.userSvc.userProfile.email;
    //console.log("userEmail: " + userEmail);

    userPrivateNetworks.guestNetworks.forEach((element) => {
      userPrivateNetworksIds.push(element.networkId);
    });
    webinarInfo.promotingNetworks.forEach((element) => {
      webinarPromotingNetworksIds.push(element.networkId);
    });
    webinarInfo.invitedPrivateNetworks.forEach((element) => {
      webinarInvitedPrivateNetworksIds.push(element.networkId);
    });

    if (
      this.userSvc.userCategory === UserCategory.Guest &&
      !this.isInNetwork(webinarPromotingNetworksIds, userPrivateNetworksIds) &&
      !this.isInArray(this.userSvc.userId, webinarInfo.involvedPeople) &&
      !this.isInArray(this.userSvc.userId, webinarInfo.internalSpeakers) &&
      !webinarInfo.externalSpeakers.includes(userEmail) &&
      !this.isInNetwork(
        webinarInvitedPrivateNetworksIds,
        userPrivateNetworksIds
      ) &&
      !this.isInArray(this.userSvc.userId, webinarInfo.remoteParticipants) &&
      !this.isEmailContainedInOtherRecipients(
        webinarInfo.otherRecipients,
        userEmail
      ) &&
      !this.isEmailContainedInOtherParticipants(
        webinarInfo.otherParticipants,
        userEmail
      )
    ) {
      this.router.navigate(["unauthorized"]);
      return false;
    }
    return true;
  }

  isEmailContainedInOtherRecipients(otherRecipients, email: string): boolean {
    if (otherRecipients === null || !otherRecipients.includes(email)) {
      return false;
    } else {
      return true;
    }
  }

  isEmailContainedInOtherParticipants(
    otherParticipants,
    email: string
  ): boolean {
    if (otherParticipants === null || !otherParticipants.includes(email)) {
      return false;
    } else {
      return true;
    }
  }

  isInNetwork(array1: String[], array2: String[]): boolean {
    const intersection = array1.filter((element) => array2.includes(element));
    return intersection.length !== 0 ? true : false;
  }

  isInArray(userId: String, people: UserInfo[]): boolean {
    people.forEach((element) => {
      element.userId === userId;
    });
    if (people.filter((e) => e.userId === userId).length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
