import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  KMSObject,
  ParentPageForAvatar,
  Role,
  UserCategory,
} from "src/app/shared/entities";
import { NetworkService } from "src/app/services/network.service";
import { HashtagService } from "src/app/services/hashtag.service";
import { IkService } from "src/app/services/ik.service";
import { Observable, BehaviorSubject } from "rxjs";
import { WebinarsService } from "src/app/services/webinars.service";
import { ExternalNetworkService } from "src/app/services/external-network.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { map, takeUntil } from "rxjs/operators";
import { NewsfeedService } from "src/app/services/newsfeed.service";
import { BaseComponent } from "src/app/shared/base.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "kms-avatar-view",
  templateUrl: "./avatar-view.component.html",
  styleUrls: ["./avatar-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AvatarViewComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input()
  imageUrl: string;

  @Input()
  isKO = false;

  @Input()
  type: KMSObject;

  @Input()
  zoomIn = false;

  @Input()
  smallVersion = false;

  @Input()
  smallerVersion = false;

  @Input()
  smVersion = false;

  @Input()
  extraSmallVersion = false;

  @Input()
  superSmallVersion = false;

  @Input()
  superExtraSmallVersion = false;

  @Input()
  mediumVersion = false;

  @Input()
  mSmallVersion = false;

  @Input()
  color: string;

  @Input()
  canEditImage = false;

  @Output()
  edit = new EventEmitter();

  @Input()
  roles: Role = null;

  @Input()
  koBadgeCssFromPage = null;

  @Input()
  ranking: any;

  @Input()
  isPrivate = false;

  @Input()
  isMobile = false;

  @Input()
  isMigrated = false;

  hasLeftFocus = true;
  imgUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  imgData$: Observable<any> = null;

  ParentPageForAvatar = ParentPageForAvatar;
  userRoles: Role = null;
  koTooltip = "";
  isOtherRole = false;
  otherTooltip = "";

  get isGuest() {
    return (
      this.userSvc.userProfile &&
      this.userSvc.userCategory === UserCategory.Guest
    );
  }

  get showInfo() {
    return this.isGuest === false && this.isPrivate === false;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private userSvc: UserService,
    private networkSvc: NetworkService,
    private externalNetworkSvc: ExternalNetworkService,
    private configSvc: ConfigurationService,
    private hashtagSvc: HashtagService,
    private ikSvc: IkService,
    private webinarsService: WebinarsService,
    private newsfeedService: NewsfeedService,
    private domSanitizationSvc: DomSanitizer,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.configSvc.assetsConfig
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((val: [string, string]) => {
        if (val) {
          this.downloadAvatarUrl();
        }
      });
    if (this.roles) {
      this.getKoTooltip();
      this.getIsOtherRole();
      this.getOtherTooltip();
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change["imageUrl"]) {
      if (this.configSvc.assetsConfig.value) {
        // if (change["imageUrl"].currentValue) {
        //   this.imageUrl = change["imageUrl"].currentValue;
        // }
        this.userRoles = null; // Fix to recalculate roles when changing profile page
        this.downloadAvatarUrl();
      }
    }
  }

  downloadAvatarUrl() {
    if (this.imageUrl) {
      if (this.type === KMSObject.WebinarsPerson) {
        const call = this.webinarsService.getWebinarsFaceAvatar(this.imageUrl);
        this.setupImgDataWebinar(call);
      } else {
        const url = this.configSvc.createCDNImageUrl(
          this.imageUrl,
          this.smallVersion
        );
        this.imgUrl$.next(url);
      }
    } else {
      if (this.type === KMSObject.User) {
        this.getDefaultUserAvatar();
      } else if (this.type === KMSObject.Network) {
        this.getDefaultNetworkAvatar();
      } else if (this.type === KMSObject.Hashtag) {
        this.getDefaultHahstagAvatar();
      } else if (this.type === KMSObject.IK) {
        this.getIKDefaultAvatar();
      } else if (this.type === KMSObject.Idea) {
        this.getIdeaDefaultAvatar();
      } else if (this.type === KMSObject.SuccessStory) {
        this.getSuccessStoryDefaultAvatar();
      } else if (this.type === KMSObject.Challenge) {
        this.getChallengeDefaultAvatar();
      } else if (this.type === KMSObject.Webinar) {
        this.getWebinarsDefaultAvatar();
      } else if (this.type === KMSObject.Conversation) {
        this.getConversationDefaultAvatar();
      } else if (this.type === KMSObject.WebinarsPerson) {
        this.getDefaultUserAvatar();
      }
    }
  }

  editImage() {
    this.edit.emit(null);
  }

  private setupImgData(call: Observable<any>) {
    call.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.imgUrl$.next(this.configSvc.createCDNImageUrl(data));
    });
  }

  private setupImgDataWebinar(call: Observable<any>) {
    this.imgData$ = call.pipe(
      map((data) => this.domSanitizationSvc.bypassSecurityTrustUrl(data))
    );
    this.cd.markForCheck();
  }

  getDefaultUserAvatar() {
    const call = !this.smallVersion
      ? this.userSvc.getDefaultUserAvatar()
      : this.userSvc.getDefaultUserThumbnail();

    this.setupImgData(call);
  }

  getDefaultHahstagAvatar() {
    const call = !this.smallVersion
      ? this.hashtagSvc.getDefaultHashtagAvatar()
      : this.hashtagSvc.getDefaultHashtagThumbnail();

    this.setupImgData(call);
  }

  getDefaultNetworkAvatar() {
    if (this.isPrivate) {
      this.setupImgData(this.externalNetworkSvc.getDefaultNetworkAvatar());
    } else {
      this.setupImgData(this.networkSvc.getDefaultNetworkAvatar());
    }
  }

  getIKDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar()
      : this.ikSvc.getDefaultIKThumbnail();

    this.setupImgData(call);
  }

  getIdeaDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar(KMSObject.Idea)
      : this.ikSvc.getDefaultIKThumbnail(KMSObject.Idea);

    this.setupImgData(call);
  }

  getSuccessStoryDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar(KMSObject.SuccessStory)
      : this.ikSvc.getDefaultIKThumbnail(KMSObject.SuccessStory);

    this.setupImgData(call);
  }

  getChallengeDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar(KMSObject.Challenge)
      : this.ikSvc.getDefaultIKAvatar(KMSObject.Challenge);

    this.setupImgData(call);
  }

  // getWebinarsFaceAvatar() {
  //   const call = this.webinarsService.getWebinarsFaceAvatar(this.imageUrl);
  //   this.setupImgData(call);
  // }

  // getWebinarsAvatar() {
  //   const call = this.webinarsService.getWebinarsAvatar(this.imageUrl);
  //   this.setupImgData(call);
  // }

  getWebinarsDefaultAvatar() {
    const call = this.webinarsService.getWebinarDefaultAvatar();
    this.setupImgData(call);
  }

  getConversationDefaultAvatar() {
    const call = this.newsfeedService.getDefaultConversationAvatar();
    this.setupImgData(call);
  }

  badgeClass() {
    // const str = "ko-profile-pill";
    // return str;
    let str: string;
    switch (this.koBadgeCssFromPage) {
      case ParentPageForAvatar.ProfileHeader:
        str = "ko-profile-pill avatar-pill";
        return str;
      case ParentPageForAvatar.ProfileWidget:
        str = "ko-profile-widget-pill avatar-pill";
        return str;
      case ParentPageForAvatar.FollowPage:
        str = "ko-profile-follow-pill avatar-pill";
        return str;
      case ParentPageForAvatar.TimelineRootPost:
        str = "ko-timeline-rootpost avatar-pill sm-pill";
        return str;
      case ParentPageForAvatar.TimelineReply:
        str = "ko-timeline-reply xsmall-pill";
        return str;
      case ParentPageForAvatar.RankingPage:
        str = "ko-ranking";
        return str;
      case ParentPageForAvatar.RankingWidget:
        str = "ko-ranking-widget";
        return str;
      case ParentPageForAvatar.ContactWidget:
        str = "badge-contacts xsmall-pill";
        return str;
      default:
        str = "ko-default xsmall-pill";
        return str;
    }
  }
  otherBadgeClass() {
    // const str = "ko-profile-pill";
    // return str;
    let str: string;
    switch (this.koBadgeCssFromPage) {
      case ParentPageForAvatar.ProfileHeader:
        str = "other-profile-pill avatar-pill";
        return str;
      case ParentPageForAvatar.ProfileWidget:
        str = "other-profile-widget-pill avatar-pill";
        return str;
      case ParentPageForAvatar.FollowPage:
        str = "other-profile-follow-pill avatar-pill";
        return str;
      case ParentPageForAvatar.TimelineRootPost:
        str = "other-timeline-rootpost avatar-pill sm-pill";
        return str;
      case ParentPageForAvatar.TimelineReply:
        str = "other-timeline-reply xsmall-pill";
        return str;
      case ParentPageForAvatar.RankingPage:
        str = "other-ranking";
        return str;
      case ParentPageForAvatar.RankingWidget:
        str = "other-ranking-widget";
        return str;
      case null:
      default:
        str = "other-default xsmall-pill";
        return str;
    }
  }

  getKoTooltip() {
    this.koTooltip = "";
    if (
      this.roles &&
      this.roles.koScopeNames &&
      this.roles.koScopeNames.length > 0
    ) {
      this.koTooltip = "K.O. of:\n";
      this.roles.koScopeNames.forEach((s) => {
        this.koTooltip += s + "\n";
      });
    } else if (this.roles.koScopes && this.roles.koScopes.length > 0) {
      this.koTooltip = "K.O.";
    }

    return this.koTooltip;
  }
  getIsOtherRole() {
    this.isOtherRole =
      this.roles &&
      (this.roles.champion ||
        (this.roles.copCoFacilitatorScopes &&
          this.roles.copCoFacilitatorScopes.length > 0) ||
        (this.roles.copFacilitatorScopes &&
          this.roles.copFacilitatorScopes.length > 0) ||
        (this.roles.buFocalPointScopes &&
          this.roles.buFocalPointScopes.length > 0) ||
        (this.roles.specialTagReferentScopes &&
          this.roles.specialTagReferentScopes.length > 0) ||
        this.roles.kmTeam);
  }
  getOtherTooltip() {
    this.otherTooltip = "";
    if (this.roles.kmTeam) {
      this.otherTooltip += "KMTeam\n";
    }
    if (
      this.roles.copFacilitatorScopes &&
      this.roles.copFacilitatorScopes.length > 0 &&
      this.roles.copFacilitatorScopeNames
    ) {
      this.otherTooltip += "Facilitator of:\n";
      this.roles.copFacilitatorScopeNames.sort();
      this.roles.copFacilitatorScopeNames.forEach((val) => {
        this.otherTooltip += val + "\n";
      });
    }
    if (
      this.roles.copCoFacilitatorScopes &&
      this.roles.copCoFacilitatorScopes.length > 0 &&
      this.roles.copCoFacilitatorScopeNames
    ) {
      this.otherTooltip += "CoFacilitator of:\n";
      this.roles.copCoFacilitatorScopeNames.sort();
      this.roles.copCoFacilitatorScopeNames.forEach((val) => {
        this.otherTooltip += val + "\n";
      });
    }
    if (
      this.roles.buFocalPointScopes &&
      this.roles.buFocalPointScopes.length > 0 &&
      this.roles.buFocalPointScopesNames
    ) {
      this.otherTooltip += "Focal point of:\n";
      this.roles.buFocalPointScopesNames.sort();
      this.roles.buFocalPointScopesNames.forEach((val) => {
        this.otherTooltip += val + "\n";
      });
    }
    if (
      this.roles.specialTagReferentScopes &&
      this.roles.specialTagReferentScopes.length > 0 &&
      this.roles.specialTagReferentScopeNames
    ) {
      this.otherTooltip += "Referent of:\n";
      this.roles.specialTagReferentScopeNames.sort();
      this.roles.specialTagReferentScopeNames.forEach((val) => {
        this.otherTooltip += val + "\n";
      });
    }
    if (this.roles.champion) {
      this.otherTooltip += "Champion";
    }
  }
  getIconClass() {
    if (this.roles.kmTeam) {
      return "fas fa-atom";
    } else if (
      this.roles.copFacilitatorScopes &&
      this.roles.copFacilitatorScopes.length > 0
    ) {
      return "fa fa-users";
    } else if (
      this.roles.copCoFacilitatorScopes &&
      this.roles.copCoFacilitatorScopes.length > 0
    ) {
      return "fa fa-users";
    } else if (
      this.roles.buFocalPointScopes &&
      this.roles.buFocalPointScopes.length > 0
    ) {
      return "fa fa-sitemap";
    } else if (
      this.roles.specialTagReferentScopes &&
      this.roles.specialTagReferentScopes.length > 0
    ) {
      return "fa fa-hashtag";
    } else if (this.roles.champion) {
      return "fas fa-crown";
    }
  }

  getUserRoles(tooltip: any, type: string) {
    this.hasLeftFocus = false;
    if (this.roles && this.roles.userId && !this.userRoles) {
      this.userSvc
        .getRoles([this.roles.userId])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((el) => {
          el.forEach((item) => {
            this.userRoles = this.roles = item.roles;
            this.cd.markForCheck();
          });
          this.getKoTooltip();
          this.getIsOtherRole();
          this.getOtherTooltip();
          this.cd.markForCheck();
          tooltip.tooltip =
            type === "koTooltip" ? this.koTooltip : this.otherTooltip;
          if (!this.hasLeftFocus) {
            tooltip.show();
          }
        });
    } else {
      tooltip.show();
    }
  }
  hideTooltip(pop) {
    this.hasLeftFocus = true;
    pop.hide();
  }

  get getData() {
    return this.type === KMSObject.WebinarsPerson && this.imageUrl
      ? this.imgData$
      : this.imgUrl$;
  }
}
