import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";
import { WebinarsService } from "src/app/services/webinars.service";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";

@Component({
  selector: "kms-widget-webinars",
  templateUrl: "./webinars.component.html",
  styleUrls: ["./webinars.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WebinarsComponent implements OnInit, AfterViewInit {

  liveSoon: any[] = null;
  myBookings: any[] = null;

  currentTab = "Live Soon";

  loading = true;

  constructor(
    private readonly webinarSvc: WebinarsService,
    private el: ElementRef<HTMLElement>,
    private userSvc: UserService,
    private cd: ChangeDetectorRef,
    private router: Router ) {}

  ngOnInit() {
    window.requestIdleCallback(() => {
      this.getWebinarsLiveSoon();
      this.cd.markForCheck();
    }, { timeout: 500 });
  }

  ngAfterViewInit(): void {
    this.el.nativeElement
      .querySelector(".nav")
      .classList.add(
        "nav-tabs-transparent",
        "indicator-primary",
        "nav-tabs-full",
        "nav-tabs-2",
      );
  }

  onTabClick(tab: string) {
    this.currentTab = tab;
    switch (tab) {
      case "Live Soon":
        if (this.liveSoon == null) {
          this.getWebinarsLiveSoon();
        }
        break;
      case "My Bookings":
        if (this.myBookings == null) {
          this.getBookingsMyBoogins();
        }
        break;
    }
  }

  onViewAllClick() {
    const userId = this.userSvc.userProfile.userId;
    const userDisplayName = this.userSvc.userProfile.displayName;
    if (this.currentTab === "Live Soon") {
      this.router.navigateByUrl("webinars/calendar");
    } else {
      const filter = `status ne 'Locked' and status ne 'Canceled' and (involvedPeopleIds/any(i: i eq '${userId}') or internalSpeakersIds/any(i: i eq '${userId}'))`;
      // const refiner = this.serialize({
      //   involvedPeople: userDisplayName,
      //   internalSpeakers: userDisplayName
      //  });
      this.router.navigate(["/webinars/list"],
        {
          queryParams: {
          addFilter: filter,
          source: "bookings",
         // refiner: refiner
        }
      });
    }
  }

  getWebinarsLiveSoon() {
    this.liveSoon = [];
    this.webinarSvc.getWebinarsLiveSoon().then(response => {
      response[0].results.forEach(r => {
        this.liveSoon.push(r.document);
      });
      response[1].results.forEach( r => {
        this.liveSoon.push(r.document);
      });
      this.liveSoon.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    //  this.myBookings.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.loading = false;
      this.cd.markForCheck();
    });
  }

  getBookingsMyBoogins() {
    this.myBookings = [];
    this.webinarSvc.getMyBookings().then( response => {
      response.results.forEach( r => {
        this.myBookings.push(r.document);
      });
      this.loading = false;
      this.cd.markForCheck();
    });
  }

  get getMyBookings() {
    return this.myBookings.slice(0, 5);
  }

  get getLiveSoon() {
    return this.liveSoon.slice(0, 5);
  }

  // private serialize(obj: any) {
  //   return Object.keys(obj)
  //     .filter(r => obj[r] && obj[r].length)
  //     .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
  //     .join("&");
  // }
}
