import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContactsComponent } from "./contacts/contacts.component";
import { OpenChallengeComponent } from "./open-challenge/open-challenge.component";
import { ProfileComponent } from "./profile/profile.component";
import { VideoComponent } from "./video/video.component";
import { WrapperComponent } from "./wrapper/wrapper.component";
import { WebinarsComponent } from "./webinars/webinars.component";
import { CoreModule } from "../core/core.module";
import { TabsModule } from "ngx-bootstrap/tabs";
import { OpenChallengeTabComponent } from "./open-challenge/open-challenge-tab/open-challenge-tab.component";
import { SuccessStoryComponent } from "./success-story/success-story.component";
import { SuccessStoryTabComponent } from "./success-story/success-story-tab/success-story-tab.component";
import { RankingComponent } from "./ranking/ranking.component";
import { RankingTabComponent } from "./ranking/ranking-tab/ranking-tab.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { WebinarsWidgetTabComponent } from "./webinars/webinars-widget-tab/webinars-widget-tab/webinars-widget-tab.component";
import { WebinarsWidgetItemComponent } from "./webinars/webinars-widget-item/webinars-widget-item/webinars-widget-item.component";
import { WidgetDateModule } from "../widget-date/widget-date.module";

const DECLARATIONS = [
  ContactsComponent,
  OpenChallengeComponent,
  OpenChallengeTabComponent,
  ProfileComponent,
  VideoComponent,
  WebinarsComponent,
  WrapperComponent,
  SuccessStoryComponent,
  SuccessStoryTabComponent,
  RankingComponent,
  RankingTabComponent,
  WebinarsWidgetTabComponent,
  WebinarsWidgetItemComponent
];
const IMPORTS = [
  CommonModule,
  TooltipModule,
  CoreModule,
  TabsModule,
  WidgetDateModule
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class WidgetsModule {}
