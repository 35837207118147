import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, Input } from "@angular/core";

@Component({
  selector: "kms-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnInit {

  @ViewChild("header", { static: true }) header: ElementRef<HTMLDivElement>;
  @ViewChild("footer", { static: true }) footer: ElementRef<HTMLDivElement>;

  @Input() color = "default";
  @Input() animation: string;

  constructor() {}

  ngOnInit() {}

  get hasHeader() {
    return this.header.nativeElement
      && this.header.nativeElement.children
      && this.header.nativeElement.children.length;
  }
  get hasFooter() {
    return this.footer.nativeElement
      && this.footer.nativeElement.children
      && this.footer.nativeElement.children.length;
  }
}
