import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ParentPageForAvatar } from "src/app/shared/entities";

@Component({
  selector: "kms-ranking-tab",
  templateUrl: "./ranking-tab.component.html",
  styleUrls: ["./ranking-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RankingTabComponent implements OnInit {
  @Input() element;
  @Input() index;
  ParentPageForAvatar = ParentPageForAvatar;
  isMobile = false;

  constructor(private readonly configSvc: ConfigurationService) {}
  
  ngOnInit() {
    this.configSvc.isMobile.subscribe(el => {
      this.isMobile = el;
    });
  }
}
