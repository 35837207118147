import { Component, Input, OnInit } from "@angular/core";
import { Contact } from "src/app/shared/entities";
  
@Component({
 selector: "kms-contacts-list",
 templateUrl: "./contacts-list.component.html",
 styleUrls: ["./contacts-list.component.scss"]
})
export class ContactsListComponent{
      
 @Input() contactsList:Contact[];

 constructor() {}    
    
 ngOnInit() {}  

}
  