import { Directive, ElementRef, HostBinding, HostListener } from "@angular/core";

@Directive({
  // tslint:disable directive-selector
  selector: ".btn"
})
export class ButtonOverridesDirective {
  constructor(private readonly el: ElementRef<HTMLElement>) {}

  @HostListener("click")
  onclick() {
    this.el.nativeElement.blur();
  }
}
