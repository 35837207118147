import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "a[data-toggle=collapse][href]"
})
export class SuppresNavigationDirective {
  constructor() {}

  @HostListener("click", ["$event"])
  onclick(event: Event) {
    event.preventDefault();
  }
}
